import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navigation from './Navigation';
import {getCurrency, getRecipeEkoPros, getRecipePortionPrice} from './Utils/RecipeTools'
import PaginationList from './Utils/PaginationList';
import erkconfig from "./Configuration"

const Recipes = ({ children }) => {

    const { t } = useTranslation();

    const token = localStorage.getItem('t');

    const [recipes, setRecipes] = React.useState(null);
    const [filterStr, setFilterStr] = React.useState("");
    const [filterOwn, setFilterOwn] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState(false);
    const [filterMaincourse, setFilterMaincourse] = React.useState(false);
    const [filterVege, setFilterVege] = React.useState(false);
    const [filterSalad, setFilterSalad] = React.useState(false);
    const [filterOthers, setFilterOthers] = React.useState(false);
    const [sortby, setSortBy] = React.useState("NAME");
    const [sort, setSort] = React.useState("asc");

    const navigate = useNavigate();

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }

    const handleFilterCheckChange = (event) => {
        let field = event.target.id;
        if (field=='filterOwn') {
            setFilterOwn(!filterOwn);
        } else if (field=='filterModel') {
            setFilterModel(!filterModel);
        } else if (field=='filterMaincourse') {
            setFilterMaincourse(!filterMaincourse);
        } else if (field=='filterVege') {
            setFilterVege(!filterVege);
        } else if (field=='filterSalad') {
            setFilterSalad(!filterSalad);
        } else if (field=='filterOthers') {
            setFilterOthers(!filterOthers);
        }
    }

    const editRecipe = (event, item) => {
        event.preventDefault();
        navigate("/recipe/" + item.id)
    }

    const deleteRecipe = (event, recipe) => {
        event.preventDefault();
        if (confirm(t('recipes.delete-confirm') + recipe.NAME + "?")) {
            let params = {
                token: token,
                recipeid: recipe.id 
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=delete', params)
                .then(function (response) {
                    if (response.data.success) {
                        loadRecipes();
                    }
                })
                .catch(function(error) {
                });
    
        }
        
    }

    const loadRecipes = () => {
        setRecipes(null)
        let params = {
            token: token,
        };
        if (sortby) {
            params.sortby = sortby
        }
        if (sort) {
            params.sort = sort
        }
        if (filterStr) {
            params.filter = filterStr
        }
        if (filterOwn) {
            params.filterown = true
        }
        if (filterModel) {
            params.filterModel = true
        }
        if (filterMaincourse) {
            params.filterMaincourse = true
        }
        if (filterVege) {
            params.filterVege = true
        }
        if (filterSalad) {
            params.filterSalad = true
        }
        if (filterOthers) {
            params.filterOthers = true
        }
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setRecipes(response.data.recipes)
            }
        })
        .catch(function(error) {
        });
    }

    const ItemName = (item) => {
        let newname = item.NAME;
        return newname;
    }

    const displayRecipeRow = (item, props) => {
        return (
            <div key={item.id} className="row table-row" onDoubleClick={(event) => editRecipe(event, item)}>
                <div className="col-3 br-d">
                    <ItemName {...item} />
                </div>
                <div className="col-1 br-d justify-content-center"><a href="#" onClick={(event) => editRecipe(event, item)}><i className="bi bi-gear-fill checkmark"></i></a></div>
                <div className="col-1 br-d justify-content-end">{item.PORTIONS}</div>
                <div className="col-2 br-d">{item.TYPE}</div>
                <div className="col-1 br-d justify-content-end">{getRecipePortionPrice(item)}&euro;</div>
                <div className="col-1 br-d justify-content-end">{getRecipeEkoPros(item)}%</div>
                <div className="col-1 br-d justify-content-center"><a href={erkconfig.BASEURL + '/jaa-resepti?recipeid=' + item.id} target="_blank"><i className="bi bi-share-fill checkmark-dark"></i></a></div>
                <div className="col-1 br-d justify-content-center"><a href={erkconfig.BASEURL + '/apps/resepti-tulostus-pdf?recipeid=' + item.id} target="_blank"><i className="bi bi-printer-fill checkmark-dark"></i></a></div>
                <div className="col-1 justify-content-center">
                    {item.user &&
                        <a href="#" onClick={(event) => deleteRecipe(event, item)}><i className="bi bi-x-circle-fill checkmark-dark"></i></a>
                    }
                </div>
            </div>
        );
    }

    useEffect(() => {
        loadRecipes();
	}, [filterOwn, filterModel, filterMaincourse, filterVege, filterSalad, filterOthers])

    useEffect(() => {
        if (filterStr=='' || filterStr.length>2) {
            loadRecipes();
        }
	}, [filterStr])

    useEffect(() => {
		if (!recipes) {
            loadRecipes();
		}
	}, [])

	return (
        <>

            <div className="content-box green">
                <div className="row align-items-end">
                    <div className="col-md-5">
                        <h3>{t('recipes.search-title')}</h3>
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" placeholder={t('recipes.search-placeholder')} aria-label={t('recipes.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                            <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterOwn} id="filterOwn" name="filterOwn" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterOwn">{t('recipes.my-recipes')}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterMaincourse} id="filterMaincourse" name="filterMaincourse" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterMaincourse">{t('recipes.main-recipes')}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterOthers} id="filterOthers" name="filterOthers" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterOthers">{t('recipes.addon-recipes')}</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterModel} id="filterModel" name="filterModel" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterModel">{t('recipes.template-recipes')}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterVege} id="filterVege" name="filterVege" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterVege">{t('recipes.vege-recipes')}</label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={filterSalad} id="filterSalad" name="filterSalad" onClick={handleFilterCheckChange} />
                                    <label className="form-check-label" htmlFor="filterSalad">{t('recipes.salad-recipes')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <button className="btn btn-default" onClick={() => navigate("/replacerecipes/")}>{t('recipes.replacebtn')}</button>
                    </div>
                </div>
            </div>

            <Navigation />

            <div className="content-box">
                <div className="row table-header align-items-end">
                    <div className="col-3 titlehead br-d">{t("recipes.table-name")}</div>
                    <div className="col-1 br-d justify-content-center">{t("recipes.table-edit")}</div>
                    <div className="col-1 br-d justify-content-center" dangerouslySetInnerHTML={{__html: t('recipes.table-portions')}}></div>
                    <div className="col-2 br-d">{t("recipes.table-type")}</div>
                    <div className="col-1 br-d justify-content-center" dangerouslySetInnerHTML={{__html: t('recipes.table-price')}}></div>
                    <div className="col-1 br-d justify-content-center" dangerouslySetInnerHTML={{__html: t('recipes.table-eko')}}></div>
                    <div className="col-1 br-d justify-content-center">{t("recipes.table-share")}</div>
                    <div className="col-1 br-d justify-content-center">{t("recipes.table-print")}</div>
                    <div className="col-1 justify-content-center">{t("recipes.table-delete")}</div>
                </div>

                {recipes ? (
                    <PaginationList data={recipes} limit={30} itemrender={displayRecipeRow} itemaction={displayRecipeRow}/>
                ) : (
                    <div className="mx-auto my-3"><div className="spinner-grow p-3"></div></div>
                )
                }

            </div>
        </>
    )
}
export default Recipes;
