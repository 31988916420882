import React, { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Outlet
} from "react-router-dom";
import axios from 'axios';
import erkconfig from "./Configuration"
import Navigation from './Navigation';
import NoUser from './NoUser';

const EkoruokakoneApp = () => {
    const { t } = useTranslation();
    const token = localStorage.getItem('t');
    const [username, setUsername] = React.useState(localStorage.getItem('un'));
    const [isLogginIn, setIsLogginIn] = React.useState(false);

    const logout = (event) => {
        event.preventDefault();
        localStorage.removeItem('t');
        localStorage.removeItem('un');
        location.href = erkconfig.BASEURL + "/apps/login?action=logout&token=" + token;
    }

    useEffect(() => {
		if (!username) {

            setIsLogginIn(true);

            let postparams = {
                token: token
            };
            axios.post(erkconfig.BASEURL + '/apps/login?action=getuser', postparams)
            .then(function (response) {
                if (response.data.success) {
                    let un = response.data.username;
                    setUsername(un)
                    localStorage.setItem('un', un);
                    setIsLogginIn(false);
                }
            })
            .catch(function(error) {
            });
		}
	})

    return (
        <>  
            <header className="noprint">
                <div className="container">
		            <div className="d-flex flex-row justify-content-between align-items-center">
                        <a href="/">
                            <img src={erkconfig.BASEURL + "/resources/images/logo.png"} alt="Ekoruokakone" />
	                    </a>
                        <div>
                            <span className="d-inline-block pe-3 me-3 text-white" style={{borderRight: "1px solid #fff"}}>{username}</span>
                            <a href="" onClick={logout} className="signout-link">
                                <i className="bi bi-box-arrow-right"></i> Kirjaudu ulos
                            </a>
                        </div>
                    </div>
                </div>
            </header>

            <div className="container">
                {(username || isLogginIn)
                    ? <Outlet />
                    : <NoUser />
                }
            </div>

            <footer className="rows-section noprint">
                <div className="container">
                    <div className="row align-items-start gx-6">
                        <div className="col-md-4">
                            <a href="https://sakky.fi/fi/ekocentria" target="_blank">
                                <img className="img-fluid" src={erkconfig.BASEURL + "/resources/images//ecocentria_koulutuskuntayhtyma_logo_trans.png"} alt="Ekocenteria"/>
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.sakky.fi/" target="_blank">
                                <img className="img-fluid" style={{marginTop: "10px"}}  src={erkconfig.BASEURL + "/resources/images//Savon_koulutus_oy_tunnus_mustalla.png"} alt="Sakky" />
                            </a>
                        </div>
                        <div className="col-md-4">
                            <a href="https://www.luomuravintola.fi/" target="_blank">
                                <img className="img-fluid" style={{maxWidth: "220px", marginTop: "40px"}} src={erkconfig.BASEURL + "/resources/images//Luomua_valkoinen.png"} alt="Luomuravintola.fi" />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default EkoruokakoneApp;