import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import erkconfig from "../Configuration"
import {getRecipePortionPrice, getRecipeEkoPros} from '../Utils/RecipeTools'


const SimpleWeekList = (props) => {

    const { t } = useTranslation();
    const token = localStorage.getItem('t');

    moment.locale("fi")

    const WeekDay = ({daynro}) => {
        let thisday = props.selectedDate.date.clone().weekday(daynro);
        let thisdaystr = thisday.format('YYYYMMDD');
        let selectedstr = props.selectedDate.date.format('yyyyMMDD');

        let thisdayrecipes = []
        if (props.weekData && props.weekData.recipes && props.weekData.recipes.length>0) {
            props.weekData.recipes.forEach((weekrecipewitem) => {
                if (thisday.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                    thisdayrecipes.push(weekrecipewitem)
                }
            });
        }
        // sort by type
        thisdayrecipes.sort((a,b) => (a.type > b.type) ? -1 : ((b.type > a.type) ? 1 : 0))

        return (
            <div className="row mb-2 compare-day-row">
                <div className="col-md-12 pb-3">

                    {(thisday.format('yyyyMMDD') == selectedstr) 
                    ? <h3 className="daytitle active"><i className="bi bi-check-square noprint"></i> {thisday.format('dddd')} - {thisday.format('D.M.yyyy')}</h3>
                    : <h3 className="daytitle" onClick={() => props.handleDateChange(thisdaystr, props.position)}><i className="bi bi-square noprint"></i> {thisday.format('dddd')} - {thisday.format('D.M.yyyy')}</h3>
                    }

                    {(thisdayrecipes.length>0) 
                    ? (
                        <>
                        <div className="row table-header small align-items-end">
                            <div className="col-6">{t("lists.table-recipe")}</div>
                            <div className="col-1 br-d justify-content-center noprint"></div>
                            <div className="col-2 br-d justify-content-center">&euro;</div>
                            <div className="col-2 br-d justify-content-center">%</div>
                            <div className="col-1 justify-content-center noprint"></div>
                        </div>
                        {thisdayrecipes.map((weekrecipewitem, wr) => (
                            <React.Fragment key={weekrecipewitem.timestamp + wr}>
                            {(thisday.format('DD.MM.yyyy') == weekrecipewitem.timestamp) &&
                                <div key={weekrecipewitem.timestamp} className="row table-row small">
                                    <div className="col-6">{weekrecipewitem.name}</div>
                                    <div className="col-1 br-d justify-content-cente noprint">
                                        <img className="popover__trigger cursor-pointer" src={erkconfig.BASEURL + "/resources/images//check_change.png"} alt={t('lists.changerecipe')} title={t('lists.changerecipe')} onClick={(event) => props.changeRecipeRow(event, thisdaystr, weekrecipewitem.recipe.id, props.positionid)} />
                                    </div>
                                    <div className="col-2 br-d justify-content-center">{getRecipePortionPrice(weekrecipewitem.recipe)}&euro;</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipeEkoPros(weekrecipewitem.recipe)}%</div>
                                    <div className="col-1 justify-content-center noprint"><a href="#" onClick={(event) => props.deleteRecipeRow(event, weekrecipewitem, props.positionid)}><i className="bi bi-x-circle-fill checkmark-dark small"></i></a></div>
                                </div>
                            }
                            </React.Fragment>
                        ))}
                        <div className="d-flex py-2 noprint">
                            <div className="flex-grow-1">
                                <a className="btn btn-secondary btn-sm mt-1 me-2" href="#" onClick={(event) => props.addRecipeRow(event, thisdaystr, props.positionid)}>{t("lists.addrecipetodaylink")}</a>
                            </div>
                            <a className="btn btn-default link-warning btn-sm mt-1" href="#" onClick={(event) => props.clearToday(event, thisdaystr, props.positionid)}>{t("lists.cleartodaylink")}</a>
                        </div>
                        </>
                    ) : (
                        <>
                            <p className="small">{t("lists.addrecipetoday")} <a className="noprint" href="#" onClick={(event) => props.addRecipeRow(event, thisdaystr, props.positionid)}>{t("lists.addrecipetodaylink")}</a></p>
                        </>
                    )
                    }
                </div>
            </div>	
        )
    }

    useEffect(() => {
		if (props.weekData) {
            //console.log(props.weekData)
		}
	}, [props.weekData])

    useEffect(() => {
		if (props.selectedDate) {
            //console.log(props.selectedDate)
		}
	}, [props.selectedDate])

	return (
        <>
            <WeekDay daynro={0} />
            <WeekDay daynro={1} />
            <WeekDay daynro={2} />
            <WeekDay daynro={3} />
            <WeekDay daynro={4} />
            <WeekDay daynro={5} />
            <WeekDay daynro={6} />
        </>
    )
}
export default SimpleWeekList;