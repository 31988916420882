import React from 'react';
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation';
import erkconfig from "./Configuration"

const Start = ({ children }) => {
	return (
        <>  
            <div>
                <img className="header-icons" alt="Ruokaikonit" src={erkconfig.BASEURL + "/resources/images/header_ikonit.png"} />
            </div>

            <Navigation />
            <div className="content-box">
                <h1>Tervetuloa Ekoruokakoneeseen</h1>
                <p>Portaat luomuun -ohjelmaan kuuluvat organisaatiot voivat käyttää Ekoruokakonetta täysi mittaisesti.</p>
                <p>Voit luoda työkaluun oman luomureseptipankin ja testata, miten luomutuotteiden vaihtaminen tai lisääminen reseptiin vaikuttaa annoshintaan sekä luomuprosenttiin.</p>
                <p>Tee oma ruokalista -sivulta voit tulostaa päivän tai koko viikon ruokalistan, joka kertoo asiakkaille, kuinka paljon luomua aterialla on ja mitkä tuotteet ovat luomua.</p>
                <p>Raaka-ainelistauksesta löydät ammattikeittiöiden käyttöön saatavilla olevien luomutuotteiden toimittajatietoja.</p>
                <p>Löydät työkalusta myös runsaasti raaka-aineisiin liittyvää ympäristö- ja ravitsemustietoa.</p>
                <table border="0" cellPadding="0" cellSpacing="0" id="etusivu-table">
                    <tbody>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public//etusivu//tee_resepti.png"} /></td>
                            <td className="nostonumber">1</td>
                            <td><em>Tee oma resepti</em><br />
                            Etsi ja lis&auml;&auml; raaka-aineita reseptiisi</td>
                        </tr>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public/etusivu//ruokalista.png"} /></td>
                            <td className="nostonumber">2</td>
                            <td><em>Tee oma ruokalista</em><br />
                            Etsi ja lis&auml;&auml; reseptej&auml; ruokalistaasi, voit tulostaa n&auml;m&auml; my&ouml;hemmin</td>
                        </tr>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public/etusivu/vertaile.png"} /></td>
                            <td className="nostonumber">3</td>
                            <td><em>Vertaile</em><br />
                            Lis&auml;&auml; luomua ruokalistalle ja vertaile vastaavaan ei luomutettuun listaan</td>
                        </tr>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public/etusivu//resepti_haku.png"} /></td>
                            <td className="nostonumber">4</td>
                            <td><em>Reseptihaku</em><br />
                            Etsi ja muokkaa tai jaa reseptej&auml;si</td>
                        </tr>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public/etusivu//raaka_aineet.png"} /></td>
                            <td className="nostonumber">5</td>
                            <td><em>Raaka-ainehaku</em><br />
                            Etsi ja anna raaka-aineille uusi, oma hinta</td>
                        </tr>
                        <tr>
                            <td><img alt="" src={erkconfig.BASEURL + "/resources/public/etusivu//info.png"} /></td>
                            <td className="nostonumber">6</td>
                            <td><em>Katso ohjeesta vinkkej&auml;</em></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </>
    )
}
export default Start;