import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import erkconfig from "../Configuration"

const SeasonMaterials = (props) => {

    const { t } = useTranslation();

    const token = localStorage.getItem('t');

    const [seasons, setSeasons] = React.useState(null);
    const [showAll, setShowAll] = React.useState(true);

    const addProduct = (event, id) => {
        event.preventDefault();
        props.addMaterial({id: id})
    }

    const toggleShowAll = (event) => {
        event.preventDefault();
        setShowAll(!showAll);
    }

    const loadSeasons = () => {
        setSeasons(null)
        let params = {
            token: token
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=season', params)
        .then(function (response) {
            if (response.data.success) {
                setSeasons(response.data.seasons)
            }
        })
        .catch(function(error) {
        });
    }

    useEffect(() => {
		if (!seasons) {
            loadSeasons();
		}
	}, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className={(showAll) ? "blackbox" : "blackbox minified"}>
                    <a href="#" className="blackbox-toggle" onClick={toggleShowAll}>{showAll ? <i className="bi bi-x-circle-fill checkmark small"></i> : <i className="bi bi-plus-circle-fill checkmark small"></i>}</a>
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-9">
                            <h3>Nyt parhaimmillaan</h3>
                            {seasons &&
                                <>
                                {seasons.map((season, index) =>
                                    <div key={season.id} className={(index==0 ) ? "d-block mb-3" : "d-none"}>
                                        <h3 className="season-title">{season.title}</h3>
                                        {season.products &&
                                        <>
                                            {season.products.map((product, pi) =>
                                                <a key={product.id} href="#" onClick={(event) => addProduct(event, product.id)}>{product.name}{(pi<season.products.length-1) ? "," : ""}</a>
                                            )}
                                        </>
                                        }
                                    </div>
                                )}
                                </>            
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
    );

}

export default SeasonMaterials;