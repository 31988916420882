import React from 'react';
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation';

const ErrorPage = ({ children }) => {
	return (
        <div className="content-box">
            <h1>Virhe</h1>
            <p>Sivstolla tapahtui vikatilanne mutta ei hätää. Ole hyvä ja kokeile uudestaan.</p>
        </div>
    )
}
export default ErrorPage;