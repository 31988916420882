import { t } from 'i18next';
import React, {useState, useEffect} from 'react'
import Pagination from './Pagination';

const PaginationList = (props) => {

    const [data, setData] = React.useState(props.data);
    const [currentPage, setCurrentPage] = useState(1);
    // No of Records to be displayed on each page   
    const [recordsPerPage] = useState(props.limit ? props.limit : 10);

    const [currentRecords, setCurrentRecords] = useState([]);
    const [nPages, setNPages] = useState(1);


    const setPage = (page) => {
        //console.log(page)
        setCurrentPage(page)
    }

    const initPagination = () => {

        //setPage(1)

        // init pagination
        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

        // Records to be displayed on the current page
        setCurrentRecords(data.slice(indexOfFirstRecord, indexOfLastRecord))
        setNPages(Math.ceil(data.length / recordsPerPage))
    }

    const BasicItemRender = (item) => {
        return (
        <button key={item.id} className="list-group-item list-group-item-action">
            {item.name ? item.name : item.id}
        </button>
        );
    }
    const ItemRender = props.itemrender ? props.itemrender : BasicItemRender;

    useEffect(() => {
		if (props.data) {
            setData(props.data);
		}
	}, [props.data])

    useEffect(() => {
		if (data) {
            initPagination();
		}
	}, [data, currentPage])


	return (
    <div className="pagination-list">
        {currentRecords &&
            <div className="list-group mb-3">
            {currentRecords.map((item) =>
                <ItemRender key={item.id} {...item} />
            )}
            </div>
        }
        <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setPage}
        />
	</div>);
}

export default PaginationList;