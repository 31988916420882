import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import Navigation from '../Navigation';
import erkconfig from "../Configuration"
import SimpleWeekList from './SimpleWeekList'
import NutritionComparator from './NutritionComparator'
import {checkNum, getRecipePortionPrice, getRecipeEkoPros} from '../Utils/RecipeTools'


const CompareWeek = ({ children }) => {

    const { t } = useTranslation();
    const token = localStorage.getItem('t');

    moment.locale("fi")

    const [filterStr, setFilterStr] = React.useState("");
    const [filteredRecipes, setFilteredRecipes] = React.useState([]);
    const [filterOwn, setFilterOwn] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState(false);
    const [filterMaincourse, setFilterMaincourse] = React.useState(false);
    const [filterVege, setFilterVege] = React.useState(false);
    const [filterSalad, setFilterSalad] = React.useState(false);
    const [filterOthers, setFilterOthers] = React.useState(false);
    const [filterAddon, setFilterAddon] = React.useState(false);
    const [filterMiddle, setFilterMiddle] = React.useState(false);
    const [filterDessert, setFilterDessert] = React.useState(false);
    
    const [sortby, setSortBy] = React.useState("NAME");
    const [sort, setSort] = React.useState("asc");
    const [changedRecipeId, setChangedRecipeId] = React.useState(null);
    const [addRecipeDialogVisible, setAddRecipeDialogVisible] = React.useState('');
    const [weeksWithRecipes, setWeeksWithRecipes] = React.useState(null);
    const [weekDataLeft, setWeekDataLeft] = React.useState({
        recipes: []
    });
    const [weekDataRight, setWeekDataRight] = React.useState({
        recipes: []
    });
    const [selectedWeekLeft, setSelectedWeekLeft] = React.useState('');
    const [selectedWeekRight, setSelectedWeekRight] = React.useState('');
    const [selectedDateLeft, setSelectedDateLeft] = React.useState({
        date: moment(),
        week: moment().week(),
        weekstr: moment().week() + "|" + moment().year(),
        year: moment().year()
    });
    const [selectedDateRight, setSelectedDateRight] = React.useState({
        date: moment(),
        week: moment().week(),
        weekstr: moment().week() + "|" + moment().year(),
        year: moment().year()
    });

    const [savedWeekData, setSavedWeekData] = React.useState([]);
    const [selectedSavedWeekId, setSelectedSavedWeekId] = React.useState('');
    const [lastSavedWeekId, setLastSavedWeekId] = React.useState('');

    const [compareSaved, setCompareSaved] = React.useState(false);
    const [compareLoaded, setCompareLoaded] = React.useState(false);


    const [isDemoUser, setIsDemoUser] = React.useState(true);


    const handleSavedCompareChange = (event) => {
        let value = event.target.value;
        setSavedCompareName(value);
    }

    const handleDateChangeLeft = (date) => {
        let newdate = moment(date, 'YYYYMMDD')
        let week = newdate.week()
        let year = newdate.year()
        setSelectedDateLeft({
            date: newdate,
            week: checkNum(week),
            weekstr: newdate.week() + "|" + newdate.year(),
            year: checkNum(year),
        });
    }
    const handleDateChangeRight = (date) => {
        let newdate = moment(date, 'YYYYMMDD')
        let week = newdate.week()
        let year = newdate.year()
        setSelectedDateRight({
            date: newdate,
            week: checkNum(week),
            weekstr: newdate.week() + "|" + newdate.year(),
            year: checkNum(year),
        });
    }

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }


    const handleFilterCheckChange = (event) => {
        let field = event.target.id;
        if (field=='filterOwn') {
            setFilterOwn(!filterOwn);
        } else if (field=='filterModel') {
            setFilterModel(!filterModel);
        } else if (field=='filterMaincourse') {
            setFilterMaincourse(!filterMaincourse);
        } else if (field=='filterVege') {
            setFilterVege(!filterVege);
        } else if (field=='filterSalad') {
            setFilterSalad(!filterSalad);
        } else if (field=='filterOthers') {
            setFilterOthers(!filterOthers);
        } else if (field=='filterAddon') {
            setFilterAddon(!filterAddon);
        } else if (field=='filterMiddle') {
            setFilterMiddle(!filterMiddle);
        } else if (field=='filterDessert') {
            setFilterDessert(!filterDessert);
        }
    }

    const addRecipeRow = (event, date, positionid) => {
        event.preventDefault();
        if (!date) {
            date = selectedDateLeft.date.format('yyyyMMDD');
        }
        setFilterStr("");
        if (positionid=='left') {
            handleDateChangeLeft(date);
        } else {
            handleDateChangeRight(date);
        }
        setAddRecipeDialogVisible(positionid)
    }
    const addRecipe = (recipe) => {
        let week = "";
        let datestr = "";
        if (addRecipeDialogVisible=='left') {
            week = selectedDateLeft.date.format('W')
            datestr = selectedDateLeft.date.format('yyyyMMDD')
        } else if (addRecipeDialogVisible=='right') {
            week = selectedDateRight.date.format('W')
            datestr = selectedDateRight.date.format('yyyyMMDD')
        }
        let params = {
            token: token,
            recipeid: recipe.id,
            week: week,
            datestr: datestr
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=addrecipe', params)
        .then(function (response) {
            if (response.data.success) {
                //console.log(response.data.item)
                // add new item to weekdata
                let recipes;
                if (addRecipeDialogVisible=='left') {
                    recipes = weekDataLeft.recipes;
                } else if (addRecipeDialogVisible=='right') {
                    recipes = weekDataRight.recipes;
                }
                console.log(recipes)
                recipes.push(response.data.item)
                if (addRecipeDialogVisible=='left') {
                    setWeekDataLeft({
                        ...weekDataLeft,
                        ["recipes"]: recipes
                    });
                } else if (addRecipeDialogVisible=='right') {
                    setWeekDataRight({
                        ...weekDataRight,
                        ["recipes"]: recipes
                    });
                }
                // hide modal
                setAddRecipeDialogVisible('')
            }
        })
        .catch(function(error) {
        });

    }
    const changeRecipeRow = (event, date, recipeid, positionid) => {
        event.preventDefault();
        if (positionid=='left') {
            handleDateChangeLeft(date);
        } else {
            handleDateChangeRight(date);
        }
        setChangedRecipeId(recipeid)
        setAddRecipeDialogVisible(positionid)
    }
    const changeRecipe = (recipe) => {
        let week = "";
        let datestr = "";
        if (addRecipeDialogVisible=='left') {
            week = selectedDateLeft.date.format('W')
            datestr = selectedDateLeft.date.format('yyyyMMDD')
        } else if (addRecipeDialogVisible=='right') {
            week = selectedDateRight.date.format('W')
            datestr = selectedDateRight.date.format('yyyyMMDD')
        }
        let params = {
            token: token,
            recipeid: recipe.id,
            week: week,
            datestr: datestr
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=addrecipe', params)
        .then(function (response) {
            if (response.data.success) {
                //console.log(response.data.item)
                // add new item to weekdata
                let recipes;
                if (addRecipeDialogVisible=='left') {
                    recipes = weekDataLeft.recipes;
                } else if (addRecipeDialogVisible=='right') {
                    recipes = weekDataRight.recipes;
                }
                // replace recipe
                let found = -1;
                if (recipes) {
                    for (const recipeindex in recipes) {
                        if (recipes[recipeindex].recipeid === changedRecipeId) {
                            found = recipeindex;
                        }
                    }
                    if (found) {
                        recipes[found] = response.data.item;
                    }
                }
                if (addRecipeDialogVisible=='left') {
                    setWeekDataLeft({
                        ...weekDataLeft,
                        ["recipes"]: recipes
                    });
                } else if (addRecipeDialogVisible=='right') {
                    setWeekDataRight({
                        ...weekDataRight,
                        ["recipes"]: recipes
                    });
                }
                // hide modal
                setAddRecipeDialogVisible('')
                setChangedRecipeId(null)
            }
        })
        .catch(function(error) {
        });
    }
    const deleteRecipeRow = (event, weekrecipewitem, positionid) => {
        event.preventDefault();
        let recipes;
        if (positionid=='left') {
            recipes = weekDataLeft.recipes;
        } else {
            recipes = weekDataRight.recipes;
        }
        let found = -1;
        if (recipes) {
            for (const recipeindex in recipes) {
                if (recipes[recipeindex].itemid === weekrecipewitem.itemid) {
                    found = recipeindex;
                }
            }
            if (found) {
                recipes.splice(found, 1);
            }
        }
        if (positionid=='left') {
            setWeekDataLeft({
                ...weekDataLeft,
                ["recipes"]: recipes
            });
        } else {
            setWeekDataRight({
                ...weekDataRight,
                ["recipes"]: recipes
            });
        }
    }
    const clearToday = (event, date, positionid) => {
        event.preventDefault();
        let newdate = moment(date, 'YYYYMMDD');
        let newdatestr = newdate.format('DD.MM.YYYY')
        if (confirm(t('lists.cleartodaylinkconfirm'))) {
            let recipes;
            if (positionid=='left') {
                recipes = weekDataLeft.recipes;
            } else {
                recipes = weekDataRight.recipes;
            }
            let founditems = []
            if (recipes) {
                for (const recipeindex in recipes) {
                    if (recipes[recipeindex].timestamp === newdatestr) {
                        founditems.push(recipeindex);
                    }
                }
                founditems.sort(function(a,b){ return a - b; });
                for (var i = founditems.length -1; i >= 0; i--) {
                    console.log('Removing:' + founditems[i])
                    recipes.splice(founditems[i], 1);
                    console.log(recipes)
                }
            }
            if (positionid=='left') {
                setWeekDataLeft({
                    ...weekDataLeft,
                    ["recipes"]: recipes
                });
            } else {
                setWeekDataRight({
                    ...weekDataRight,
                    ["recipes"]: recipes
                });
            }
        }
    }

    const loadWeeksWithRecipes = () => {
        //  weeklist
        setWeeksWithRecipes(null)
        let params = {
            token: token
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=weeklist', params)
        .then(function (response) {
            if (response.data.success) {
                setWeeksWithRecipes(response.data.weeks)
                if (response.data.weeks[0]) {
                    let weekitem = response.data.weeks[0];
                    let firstweek = weekitem.VKO+"|"+weekitem.YEAR
                    setSelectedWeekLeft(firstweek);
                    setSelectedWeekRight(firstweek);
                    setIsDemoUser(response.data.isdemouser)
                }
            }
        })
        .catch(function(error) {
        });

    };


    const selectWeekForLoading = (event) => {
        let value = event.target.value;
        if (value) {
            let position = event.target.id;
            if (position=='left') {
                setSelectedWeekLeft(value)
            } else {
                setSelectedWeekRight(value)
            }
        }

    }

    const loadWeek = (event, position) => {
        let selectedWeekStr = "";
        let weekstr;
        let yearstr;
        // weeklist
        if (position=='left') {
            setWeekDataLeft(null)
            selectedWeekStr = selectedWeekLeft;
        } else {
            setWeekDataRight(null)
            selectedWeekStr = selectedWeekRight;
        }

        if (selectedWeekStr) {
            let week = selectedWeekStr.split('|')[0];
            let year = selectedWeekStr.split('|')[1];
            let newdate = moment().isoWeekYear(year).isoWeek(week).startOf('week')
            weekstr = newdate.format('W');
            yearstr = newdate.format('YYYY');

            if (position=='left') {
                setSelectedDateLeft({
                    date: newdate,
                    week: checkNum(week),
                    weekstr: selectedWeekStr,
                    year: checkNum(year),
                });
            } else {
                setSelectedDateRight({
                    date: newdate,
                    week: checkNum(week),
                    weekstr: selectedWeekStr,
                    year: checkNum(year),
                });
            }
            let params = {
                week: weekstr,
                year: yearstr,
                token: token
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=getweek', params)
            .then(function (response) {
                if (response.data.success) {
                    if (position=='left') {
                        setWeekDataLeft(response.data.week)
                    } else {
                        setWeekDataRight(response.data.week)
                    }
                }
                if (response.data.isdemouser) {
                    setIsDemoUser(true);
                }


            })
            .catch(function(error) {
            });
        }
    };


    const loadCompare = () => {
        if (selectedSavedWeekId) {

            setCompareSaved(false)
            setCompareLoaded(false)
            let params = {
                token: token,
                compareid: selectedSavedWeekId
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=load', params)
            .then(function (response) {
                if (response.data.success) {
                    //console.log(response.data.compare)
                    setWeekDataLeft(response.data.compare.weekDataLeft)
                    setWeekDataRight(response.data.compare.weekDataRight)

                    let dleft = response.data.compare.selectedDateLeft;
                    dleft.date = moment(dleft.date);
                    setSelectedDateLeft(dleft)

                    let dright = response.data.compare.selectedDateRight;
                    dright.date = moment(dright.date);
                    setSelectedDateRight(dright)

                    setSavedCompareName(parseCompareName(response.data.compare.name))

                    setCompareLoaded(true)

                }
            })
            .catch(function(error) {
            });
        }
    }

    const saveCompare = () => {
        setCompareSaved(false)
        setCompareLoaded(false)
        
        let params = {
            token: token,
            compareid: selectedSavedWeekId,
            weekDataLeft: weekDataLeft,
            weekDataRight: weekDataRight,
            selectedDateLeft: selectedDateLeft,
            selectedDateRight: selectedDateRight
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=save', params)
        .then(function (response) {
            if (response.data.success) {
                setCompareSaved(true)
            }
        })
        .catch(function(error) {
        });
    }

    const saveCompareAs = () => {
        setCompareSaved(false)
        setCompareLoaded(false)
        let savename = prompt(t('compareweek.nameprompt'));
        if (savename) {
            let params = {
                token: token,
                name: savename,
                weekDataLeft: weekDataLeft,
                weekDataRight: weekDataRight,
                selectedDateLeft: selectedDateLeft,
                selectedDateRight: selectedDateRight
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=save', params)
            .then(function (response) {
                if (response.data.success) {
                    setCompareSaved(true)
                    setLastSavedWeekId(response.data.compare.id);
                    loadSavedCompares();
                }
            })
            .catch(function(error) {
            });
        }
    }

    const loadSavedCompares = () => {
        //  weeklist
        setSavedWeekData(null)
        let params = {
            token: token
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_compare?action=loadsavedcompares', params)
        .then(function (response) {
            if (response.data.success) {
                setSavedWeekData(response.data.compares)
            }
        })
        .catch(function(error) {
        });
    };

    const parseCompareName = (item) => {
        let newname = item.name;
        if (!newname) {
            let created = t('compareweek.noname') + ' ' + moment(item.created, "YYYYMMDDHHmmss").format("D.M.YYYY");
            newname = created;
        }
        return newname;
    }

    const loadRecipes = () => {
        setFilteredRecipes(null)
        let params = {
            token: token
        };
        if (sortby) {
            params.sortby = sortby
        }
        if (sort) {
            params.sort = sort
        }
        if (filterStr) {
            params.filter = filterStr
        }
        if (filterOwn) {
            params.filterown = true
        }
        if (filterModel) {
            params.filterModel = true
        }
        if (filterMaincourse) {
            params.filterMaincourse = true
        }
        if (filterVege) {
            params.filterVege = true
        }
        if (filterSalad) {
            params.filterSalad = true
        }
        if (filterAddon) {
            params.filterAddon = true
        }
        if (filterMiddle) {
            params.filterMiddle = true
        }
        if (filterDessert) {
            params.filterDessert = true
        }
        
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setFilteredRecipes(response.data.recipes)
            }
        })
        .catch(function(error) {
        });
    }
    
    let timer;
    useEffect(() => {
        if (filterStr.length>-1) {
            timer = setTimeout(() => {
                loadRecipes();
            }, 200);
        }
        
	}, [filterStr])

    useEffect(() => {
        loadRecipes();
	}, [filterOwn, filterModel, filterMaincourse, filterVege, filterSalad, filterAddon, filterMiddle, filterDessert])

    useEffect(() => {
        if (lastSavedWeekId) {
            setSelectedSavedWeekId(lastSavedWeekId);
        }
	}, [savedWeekData])

    useEffect(() => {
		if (!weeksWithRecipes) {
            loadWeeksWithRecipes();
            loadSavedCompares();
		}
	}, [])

	return (
        <>

            <div className={(addRecipeDialogVisible!=='') ? "modal fade show d-block" : "modal fade"} id="addRecipeDialog" tabIndex="-1" aria-labelledby="addRecipeDialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header flex-column pb-0">

                            <div className="d-flex w-100">
                                <div className="flex-fill">
                                    <div className="input-group mb-2 pe-2">
                                        <input ref={input => input && input.focus()} type="text" className="form-control" placeholder={t('lists.search-placeholder')} aria-label={t('lists.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                                        <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <div className="row">
                                    <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterOwn} id="filterOwn" name="filterOwn" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterOwn">{t('recipes.my-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterMaincourse} id="filterMaincourse" name="filterMaincourse" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterMaincourse">{t('recipes.main-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterVege} id="filterVege" name="filterVege" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterVege">{t('recipes.vege-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterAddon} id="filterAddon" name="filterAddon" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterAddon">{t('recipes.addon-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterSalad} id="filterSalad" name="filterSalad" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterSalad">{t('recipes.salad-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                        <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterMiddle} id="filterMiddle" name="filterMiddle" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterMiddle">{t('recipes.middle-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterDessert} id="filterDessert" name="filterDessert" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterDessert">{t('recipes.dessert-recipes')}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="d">
                                    <button type="button" className="btn-close mb-4" aria-label="Close" onClick={() => setAddRecipeDialogVisible('')}></button>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="row table-header small no-border px-2">
                                    <div className="col-5">{t('recipes.table-name')}</div>
                                    <div className="col-3">{t('recipes.table-type')}</div>
                                    <div className="col-2 justify-content-center">{t('recipes.table-price2')}</div>
                                    <div className="col-2 justify-content-center">{t('recipes.table-eko2')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pt-0 px-4" style={{minHeight: "512px", maxHeight: "512px"}}>
                            {filteredRecipes &&
                            <>
                                {filteredRecipes.map((recipe) =>
                                <div key={recipe.id} className="row table-row small cursor-pointer" onClick={() => (changedRecipeId!=null ? changeRecipe(recipe) : addRecipe(recipe))}>
                                    <div className="col-5">{recipe.NAME}</div>
                                    <div className="col-3 br-d">{recipe.TYPE}</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipePortionPrice(recipe)}&euro;</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipeEkoPros(recipe)}%</div>
                                </div>
                            )}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {(addRecipeDialogVisible!=='') &&
                <div className="modal-backdrop fade show"></div>
            } 

            <div className="content-box green noprint">
                <div className="row">
                    <div className="col-md-12">
                        <h3>{t('compareweek.title')}</h3>

                        <div className="row">
                            <div className="col-md-5">
                                {!isDemoUser &&
                                <>
                                <div className="mb-3">
                                    <div className="input-group mb-3 noprint">
                                        <select id="left" className="form-select" value={selectedSavedWeekId} onChange={(e) => setSelectedSavedWeekId(e.target.value)}>
                                            <option key={"placeholder"} selected>{t('compareweek.loadselectpalceholder')}</option>
                                            {(savedWeekData) &&
                                                <>
                                                {savedWeekData.map((savedCompare) => (
                                                    <option key={savedCompare.id} value={savedCompare.id}>{parseCompareName(savedCompare)}</option>
                                                ))}
                                                </>
                                            }
                                        </select>
                                        <button className="btn btn-secondary me-2" onClick={loadCompare}>{t('compareweek.loadcompare')}</button>
                                    </div>

                                </div>
                                {(compareLoaded) &&
                                    <span className="ms-4">{t('compareweek.loaded')}</span> 
                                }
                                </>
                                }
                            </div>
                            <div className="col-md-5">
                                {!isDemoUser &&
                                <>
                                <div className="mb-3">
                                    {selectedSavedWeekId &&
                                       <button className="btn btn-secondary me-2" onClick={saveCompare}>{t('compareweek.savecompare')}</button>
                                    }
                                    <button className="btn btn-secondary" onClick={saveCompareAs}>{t('compareweek.savecompareas')}</button>
                                    {(compareSaved) &&
                                        <span className="ms-4">{t('compareweek.saved')}</span> 
                                    }
                                </div>
                                </>
                                }
                            </div>

                            <div className="col-md-2">
                                <div className="mb-3">
                                    <button className="btn btn-default" onClick={() => { window.print()}}>{t('compareweek.print')}</button>
                                </div>
                            </div>


                            
                        </div>

                    </div>
                </div>
            </div>
            
            <Navigation />

            <div className="row">
                <div className="col-md-4">
                    <div className="content-box">
                        {!isDemoUser &&
                        <div className="input-group mb-3 noprint">
                            <select id="left" className="form-select" value={selectedWeekLeft} onChange={selectWeekForLoading}>
                                <option key={"placeholder"} selected>{t('compareweek.selectweek')}</option>
                                {(weeksWithRecipes) &&
                                    <>
                                    {weeksWithRecipes.map((weekitem, w) => (
                                        <option key={weekitem.VKO+"|"+weekitem.YEAR} value={weekitem.VKO+"|"+weekitem.YEAR}>viikko {weekitem.VKO} / {weekitem.YEAR}</option>
                                    ))}
                                    </>
                                }
                            </select>
                            <button className="btn btn-secondary" onClick={(event) => loadWeek(event, 'left')}>{t('compareweek.load')}</button>
                        </div>
                        }
                        <SimpleWeekList 
                            positionid="left"
                            weekData={weekDataLeft} 
                            selectedDate={selectedDateLeft} 
                            handleDateChange={handleDateChangeLeft} 
                            addRecipeRow={addRecipeRow} 
                            changeRecipeRow={changeRecipeRow}
                            deleteRecipeRow={deleteRecipeRow}
                            clearToday={clearToday}
                            />
                    </div>
                </div>
                <div className="col-md-4">
                    <NutritionComparator 
                        weekDataLeft={weekDataLeft} 
                        weekDataRight={weekDataRight} 
                        selectedDateLeft={selectedDateLeft} 
                        selectedDateRight={selectedDateRight} 
                        />
                </div>
                <div className="col-md-4">
                    <div className="content-box">
                        {!isDemoUser &&
                        <div className="input-group mb-3 noprint">
                            <select id="right" className="form-select" value={selectedWeekRight} onChange={selectWeekForLoading}>
                                <option key={"placeholder"} selected>{t('compareweek.selectweek')}</option>
                                {(weeksWithRecipes) &&
                                    <>
                                    {weeksWithRecipes.map((weekitem, w) => (
                                        <option key={weekitem.VKO+"|"+weekitem.YEAR} value={weekitem.VKO+"|"+weekitem.YEAR}>viikko {weekitem.VKO} / {weekitem.YEAR}</option>
                                    ))}
                                    </>
                                }
                            </select>
                            <button className="btn btn-secondary" onClick={(event) => loadWeek(event, 'right')}>{t('compareweek.load')}</button>
                        </div>
                        }

                        <SimpleWeekList 
                            positionid="right"
                            weekData={weekDataRight} 
                            selectedDate={selectedDateRight} 
                            handleDateChange={handleDateChangeRight} 
                            addRecipeRow={addRecipeRow} 
                            changeRecipeRow={changeRecipeRow}
                            deleteRecipeRow={deleteRecipeRow}
                            clearToday={clearToday}
                            />
                    </div>
                </div>
            </div>

            
        </>
    )
}
export default CompareWeek;