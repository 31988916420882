import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {getCurrency, getMaterialPrice} from '../Utils/RecipeTools'
import erkconfig from "../Configuration"

const RecipeMaterialRow = (props) => {

	const { t } = useTranslation();

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({id: props.id});
  
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};
	
	return (
		<div ref={setNodeRef} style={style} {...attributes}>
			<div className="row table-row small" >
				<div className="col-3"><i className="bi bi-grip-vertical" style={{fontSize: "1.2rem", marginLeft: "-6px"}} {...listeners}></i> {props.material.NAME}</div>
				<div className="col-1 br-d justify-content-center position-relative">
					{props.material.nutritioncomments &&
						<>
							<div className="popover__wrapper">
								<i className="bi bi-info-circle-fill checkmark-dark cursor-question popover__trigger"></i>
								<div className="popover__content">
									<p className="mb-0">{props.material.nutritioncomments}</p>
								</div>
							</div>
						</>
					}
				</div>
				<div className="col-2 br-d justify-content-center"><input id="WEIGHT" type="text" className="form-control form-control-sm text-end" value={props.material.WEIGHT} onChange={(event) => props.handleMaterialChange(event, props.material)} onBlur={(event) => props.handleMaterialBlur(event, props.material)}/></div>
				<div className="col-2 br-d justify-content-center"><input id="LOSSLESS" type="text" className="form-control form-control-sm text-end" value={props.material.LOSSLESS} onChange={(event) => props.handleMaterialChange(event, props.material)}/></div>
				<div className="col-1 br-d justify-content-end">{getCurrency(getMaterialPrice(props.material))}&euro;</div>
				<div className="col-1 br-d justify-content-center">
					{(props.material.EKO && !props.material.ORIGINALMATERIALID) &&
						<i className="bi bi-check-circle-fill ekomark eko"></i>
					}
					{(props.material.EKO && props.material.ORIGINALMATERIALID) &&
						<i className="bi bi-check-circle-fill ekomark eko cursor-pointer" title={t('recipe.changetononeko')} onClick={() => props.changeMaterialToEko(props.material)}></i>
					}
					{(!props.material.EKO && props.material.EKOMATERIAL) &&
						<>
							<div className="popover__wrapper">
								<img className="popover__trigger cursor-pointer" src={erkconfig.BASEURL + "/resources/images//check_change.png"} alt={t('recipe.changetoeko')} title={t('recipe.changetoeko')} onClick={() => props.changeMaterialToEko(props.material)}/>
								<div className="popover__content">
									<p className="mb-0 w-100 d-flex align-items-center"><i className="bi bi-check-circle-fill ekomark eko me-2"></i><span className="flex-grow-1">{props.material.EKOMATERIALNAME}</span> <span>{getCurrency(props.material.EKOMATERIALPRICE)}&euro;</span></p>
								</div>
							</div>
						</>
					}
					{(!props.material.EKO && !props.material.EKOMATERIAL) &&
						<i className="bi bi-check-circle-fill ekomark"></i>
					}
				</div>
				<div className="col-1 br-d justify-content-center">
					{props.material.commentstext &&
						<>
							<div className="popover__wrapper">
								<img className="popover__trigger" src={erkconfig.BASEURL + "/resources/images/note_" + props.material.commentsicon + ".png"} alt="" />
								<div className="popover__content">
									<p className="mb-0">{props.material.commentstext}</p>
								</div>
							</div>
						</>
					}
				</div>
				<div className="col-1 justify-content-center"><a href="#" onClick={(event) => props.deleteMaterialRow(event, props.material)}><i className="bi bi-x-circle-fill checkmark-dark"></i></a></div>
			</div>
		</div>
	);
}
export default RecipeMaterialRow;