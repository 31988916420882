import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
import BaseCircleChart from '../Utils/BaseCircleChart';
import {getWeights, getPrices, MaterialName, checkNum, roundNum, getCurrency, getNumber, getRecipePortionPrice, getRecipeEkoPros, getPortionWeight, getNutritiondata} from '../Utils/RecipeTools'
import toDate from 'date-fns/toDate/index';

const defaultnd = {
    kcal: 0,
    kj: 0,
    rasva: 0,
    rasvapros: 0,
    rasvatyyd: 0,
    rasvatyydpros: 0,
    hiilihydraatit: 0,
    hiilihydraatitpros: 0,
    hiilihydraatitsok: 0,
    hiilihydraatitsokpros: 0,
    proteiini: 0,
    proteiinipros: 0,
    ravintokuitu: 0,
    natrium: 0,
    suola: 0,
    avit: 0,
    dvit: 0,
    cvit: 0,
    b1: 0,
    folaatti: 0,
    totalweight: 0
}

const NutritionComparator = (props) => {

    const { t } = useTranslation();
    const token = localStorage.getItem('t');

    moment.locale("fi")
    registerLocale("fi", fi)

    const [ekoProsLeft, setEkoProsLeft] = React.useState(0);
    const [ekoProsRight, setEkoProsRight] = React.useState(0);
    const [portionPriceLeft, setPortionPriceLeft] = React.useState(0.00);
    const [portionPriceRight, setPortionPriceRight] = React.useState(0.00);
    const [nutritionDataLeft, setNutritionDataLeft] = React.useState(JSON.parse(JSON.stringify(defaultnd)));
    const [nutritionDataRight, setNutritionDataRight] = React.useState(JSON.parse(JSON.stringify(defaultnd)));
    const [ekoProsUsePrice, setEkoProsUsePrice] = React.useState(false);
    const [energyUsePortion, setEnergyUsePortion] = React.useState(true);

    const [useWeekCompare, setUseWeekCompare] = React.useState(true);

    const calcNutritionData = (selectedDate, weekData) => {
        if (selectedDate) {
            let nd = null;
            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (useWeekCompare || selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let pw = getPortionWeight(weekrecipewitem.recipe);
                        let ond = getNutritiondata(weekrecipewitem.recipe, pw, energyUsePortion);
                        //console.log(ond)
                        if (!nd) {
                            nd = ond;
                        } else {
                            nd.kcal = checkNum(nd.kcal) + checkNum(ond.kcal);
                            nd.kj = checkNum(nd.kj) + checkNum(ond.kj);
                            nd.rasva = nd.rasva + ond.rasva;
                            nd.rasvatyyd = checkNum(nd.rasvatyyd) + checkNum(ond.rasvatyyd);
                            nd.hiilihydraatit = checkNum(nd.hiilihydraatit) + checkNum(ond.hiilihydraatit);
                            nd.hiilihydraatitsok = checkNum(nd.hiilihydraatitsok) + checkNum(ond.hiilihydraatitsok);
                            nd.proteiini = checkNum(nd.proteiini) + checkNum(ond.proteiini);
                            nd.ravintokuitu = checkNum(nd.ravintokuitu) + checkNum(ond.ravintokuitu);
                            nd.natrium = checkNum(nd.natrium) + checkNum(ond.natrium);
                            nd.suola = checkNum(nd.suola) + checkNum(ond.suola);
                            nd.avit = checkNum(nd.avit) + checkNum(ond.avit);
                            nd.dvit = checkNum(nd.dvit) + checkNum(ond.dvit);
                            nd.cvit = checkNum(nd.cvit) + checkNum(ond.cvit);
                            nd.b1 = checkNum(nd.b1) + checkNum(ond.b1);
                            nd.folaatti = checkNum(nd.folaatti) + checkNum(ond.folaatti);
                            nd.totalweight = checkNum(nd.totalweight) + checkNum(ond.totalweight);
                        }
                        
                    }
                });
            }
            if (nd) {

                if (energyUsePortion) {
                    if (nd.totalweight) {

                        let portions = 1;
                        let portionweight = nd.totalweight / portions;

                        // calc per portion
                        let rp = (((nd.rasva / portions) * 9) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.rasvapros = rp; }
                    
                        rp = ((nd.rasvatyyd / portions) / (nd.rasva / portions)) * 100;
                        if (!isNaN(rp)) { nd.rasvatyydpros = rp; }
                    
                        rp = (((nd.hiilihydraatit / portions) * 4) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitpros = rp; }
                
                        //rp = (nd.hiilihydraatitsok / portions) * 100 / portionweight;
                        rp = ((nd.hiilihydraatitsok / portions) / (nd.hiilihydraatit / portions)) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitsokpros = rp; }
                    
                        rp = (((nd.proteiini / portions) * 4) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.proteiinipros = rp; }
                        
                    }

                }
                return nd;

            } else {
                return defaultnd;
            }
        }
    }

    const calcEkoPros = (selectedDate, weekData) => {
        if (selectedDate) {

            let totalprice = 0.00;
            let totalekoprice = 0.00;
            let totalweight = 0.00;
            let totalekoweight = 0.00;

            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (useWeekCompare || selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let portions = checkNum(weekrecipewitem.recipe.PORTIONS);
                        if (!portions) {
                            portions = 1;                            
                        }
                        let prices = getPrices(weekrecipewitem.recipe);
                        if (prices) {
                            totalprice = totalprice + prices.total;
                            totalekoprice = totalekoprice + prices.eko;
                        }
                        let weights = getWeights(weekrecipewitem.recipe, weekrecipewitem.recipe.COOKINGLOSSLESS);
                        if (weights) {
                            totalweight = totalweight + weights.total / portions;
                            totalekoweight = totalekoweight + weights.eko / portions;
                        }
                    }
                });
            }

            let newEkoPros = 0;
            if (ekoProsUsePrice) {
                newEkoPros = totalekoprice * 100 / totalprice;
            } else {
                newEkoPros = totalekoweight * 100 / totalweight;
            }
            if (!newEkoPros) {
                newEkoPros = 0;
            }
            // if 100 then downgrade to 99
            if (newEkoPros > 99 && newEkoPros < 100) {
                return 99;
            } else {
                return newEkoPros;
            }
            
        }
    }

    const calcPortionsPrice = (selectedDate, weekData) => {
        if (selectedDate) {

            let portionPrice = 0.00;
            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (useWeekCompare || selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let pp = checkNum(getRecipePortionPrice(weekrecipewitem.recipe));
                        if (pp) {
                            portionPrice = portionPrice + pp;
                        }

                        
                    }
                });
            }
            return portionPrice;
        }
    }

    const calcLeft = () => {
        let pr = calcPortionsPrice(props.selectedDateLeft, props.weekDataLeft);
        setPortionPriceLeft(pr);
        let ep = calcEkoPros(props.selectedDateLeft, props.weekDataLeft);
        setEkoProsLeft(ep)
        let nd = calcNutritionData(props.selectedDateLeft, props.weekDataLeft);
        setNutritionDataLeft(nd)
    }

    const calcRight = () => {
        let pr = calcPortionsPrice(props.selectedDateRight, props.weekDataRight);
        setPortionPriceRight(pr);
        let ep = calcEkoPros(props.selectedDateRight, props.weekDataRight);
        setEkoProsRight(ep)
        let nd = calcNutritionData(props.selectedDateRight, props.weekDataRight);
        setNutritionDataRight(nd)
    }

    useEffect(() => {
        if (props.weekDataLeft) {
            calcLeft()
        }
	}, [props.weekDataLeft, props.selectedDateLeft])

    useEffect(() => {
        if (props.weekDataRight) {
            calcRight()
        }
	}, [props.weekDataRight, props.selectedDateRight])

    useEffect(() => {
		calcLeft();
        calcRight();
	}, [ekoProsUsePrice, energyUsePortion, useWeekCompare])

	return (
        <>

            <div className="content-box print-padding">

                <div className="text-center noprint"><span id="perWeek" className={useWeekCompare ? "active_type" : "unactive_type"} onClick={(() => setUseWeekCompare(true))}>viikko</span> / <span id="perDay" className={useWeekCompare ? "unactive_type" : "active_type"} onClick={(() => setUseWeekCompare(false))}>päivä</span></div>

                {useWeekCompare
                ? (
                    <>
                        <h4 className="detailmargin">VIIKKOKOHTAISET TIEDOT<br/><span className="fs-3">{props.selectedDateLeft.date.format('W/YYYY')}</span> <span className="mx-3">-</span> <span className="fs-3">{props.selectedDateRight.date.format('W/YYYY')}</span></h4>

                    </>
                )
                : (
                    <>
                        <h4 className="detailmargin">PÄIVÄKOHTAISET TIEDOT<br/><span className="fs-3">{props.selectedDateLeft.date.format('D.M.yyyy')}</span> <span className="mx-3">-</span> <span className="fs-3">{props.selectedDateRight.date.format('D.M.yyyy')}</span></h4>
                    </>
                )}

                
                <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                    <div className="col-md-12">
                        <h4 className="detailmargin">Annoksen hinta</h4>
                    </div>
                </div>

                <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                    <div className="col-sm-6" style={{borderRight: "1px solid #ddd"}}>
                        <div className="bluebox box"><h4 id="portionprice">{getNumber(portionPriceLeft)}&euro;</h4></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="bluebox box"><h4 id="portionprice">{getNumber(portionPriceRight)}&euro;</h4></div>
                    </div>
                </div>	

                <div className="row mt-3" style={{borderBottom: "1px solid #ddd"}}>
                    <div className="col-12">
                        <h4 className="detailmargin">Luomuprosentti - <span id="ekoweight" className={ekoProsUsePrice ? "unactive_type noprint" : "active_type"} onClick={(() => setEkoProsUsePrice(false))}>Kilo</span><span className="noprint"> / </span><span id="ekoprice" className={ekoProsUsePrice ? "active_type" : "unactive_type noprint"} onClick={(() => setEkoProsUsePrice(true))}>Hinta</span> </h4>
                    </div>
                </div>

                <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                    <div className="col-sm-6" style={{borderRight: "1px solid #ddd"}}>
                        <div className="greenbox box"><h4 id="ekopros">{getNumber(ekoProsLeft, 0)}%</h4></div>
                    </div>
                    <div className="col-sm-6">
                        <div className="greenbox box"><h4 id="ekopros">{getNumber(ekoProsRight, 0)}%</h4></div>
                    </div>
                </div>		


                <div className="row mt-3" style={{borderBottom: "1px solid #ddd"}}>
                    <div className="col-12">
                    <h4 className="detailmargin">Energia - <span id="perAnnos" className={energyUsePortion ? "active_type" : "unactive_type noprint"} onClick={(() => setEnergyUsePortion(true))}>per annos</span><span className="noprint"> / </span><span id="perSata" className={energyUsePortion ? "unactive_type noprint" : "active_type"} onClick={(() => setEnergyUsePortion(false))}>per 100g</span> </h4>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6" style={{borderRight: "1px solid #ddd"}}>
                        <div className="greybox box">
                            <h4 id="portionkcal">{getNumber(nutritionDataLeft.kcal, 0)} <span className="small">kcal</span></h4>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="greybox box">
                            <h4 id="portionkcal">{getNumber(nutritionDataRight.kcal, 0)} <span className="small">kcal</span></h4>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 mt-3">
                        <h4 className="detailmargin">Ravintosisältö</h4>
                        <table className="table nutritiontable">
                            <tbody>
                                <tr>
                                    <td className="text-end pe-2"><span id="rasva_g" title={getNumber(nutritionDataLeft.rasvapros)+'%'}>{getNumber(nutritionDataLeft.rasva)}</span>g</td>
                                    <td className="text-center">rasva</td>
                                    <td className="text-end"><span id="rasva_g" title={getNumber(nutritionDataRight.rasvapros)+'%'}>{getNumber(nutritionDataRight.rasva)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="rasvatyyd_g" title={getNumber(nutritionDataLeft.rasvatyydpros)+'%'}>{getNumber(nutritionDataLeft.rasvatyyd)}</span>g</td>
                                    <td className="text-center"><small>josta tyydyttynyttä</small></td>
                                    <td className="text-end"><span id="rasvatyyd_g" title={getNumber(nutritionDataRight.rasvatyydpros)+'%'}>{getNumber(nutritionDataRight.rasvatyyd)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="hiilihydraatit_g" title={getNumber(nutritionDataLeft.hiilihydraatitpros)+'%'}>{getNumber(nutritionDataLeft.hiilihydraatit)}</span>g</td>
                                    <td className="text-center">hiilihydraatit</td>
                                    <td className="text-end"><span id="hiilihydraatit_g" title={getNumber(nutritionDataRight.hiilihydraatitpros)+'%'}>{getNumber(nutritionDataRight.hiilihydraatit)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="hiilihydraatitsok_g" title={getNumber(nutritionDataLeft.hiilihydraatitsokpros)+'%'}>{getNumber(nutritionDataLeft.hiilihydraatitsok)}</span>g</td>
                                    <td className="text-center"><small>josta sokereita</small></td>
                                    <td className="text-end"><span id="hiilihydraatitsok_g" title={getNumber(nutritionDataRight.hiilihydraatitsokpros)+'%'}>{getNumber(nutritionDataRight.hiilihydraatitsok)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="proteiini_g" title={getNumber(nutritionDataLeft.proteiinipros)+'%'}>{getNumber(nutritionDataLeft.proteiini)}</span>g</td>
                                    <td className="text-center">proteiini</td>
                                    <td className="text-end"><span id="proteiini_g" title={getNumber(nutritionDataRight.proteiinipros)+'%'}>{getNumber(nutritionDataRight.proteiini)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="ravintokuitu_g">{getNumber(nutritionDataLeft.ravintokuitu)}</span>g</td>
                                    <td className="text-center">ravintokuitu</td>
                                    <td className="text-end"><span id="ravintokuitu_g">{getNumber(nutritionDataRight.ravintokuitu)}</span>g</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="suola_g">{getNumber(nutritionDataLeft.suola)}</span>g</td>
                                    <td className="text-center">suola</td>
                                    <td className="text-end"><span id="suola_g">{getNumber(nutritionDataRight.suola)}</span>g</td>
                                </tr>						
                                <tr>
                                    <td className="text-end pe-2"><span id="avit_g">{getNumber(nutritionDataLeft.avit)}</span>µg</td>
                                    <td className="text-center">A-vit</td>
                                    <td className="text-end"><span id="avit_g">{getNumber(nutritionDataRight.avit)}</span>µg</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="dvit_g">{getNumber(nutritionDataLeft.dvit)}</span>µg</td>
                                    <td className="text-center">D-vit</td>
                                    <td className="text-end"><span id="dvit_g">{getNumber(nutritionDataRight.dvit)}</span>µg</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="cvit_g">{getNumber(nutritionDataLeft.cvit)}</span>mg</td>
                                    <td className="text-center">C-vit</td>
                                    <td className="text-end"><span id="cvit_g">{getNumber(nutritionDataRight.cvit)}</span>mg</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="b1_g">{getNumber(nutritionDataLeft.b1)}</span>mg</td>
                                    <td className="text-center">B1 tiamiini</td>
                                    <td className="text-end"><span id="b1_g">{getNumber(nutritionDataRight.b1)}</span>mg</td>
                                </tr>
                                <tr>
                                    <td className="text-end pe-2"><span id="folaatti_g">{getNumber(nutritionDataLeft.folaatti)}</span>µg</td>
                                    <td className="text-center">folaatti</td>
                                    <td className="text-end"><span id="folaatti_g">{getNumber(nutritionDataRight.folaatti)}</span>µg</td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>		

            </div>
            
        </>
    )
}
export default NutritionComparator;