import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    NavLink
} from "react-router-dom";

const Navigation = ({ children }) => {
    const { t } = useTranslation();
	return (
        <div className="erk-nav noprint">
            <NavLink to="/" className={({ isActive }) => isActive ? "nav-link omat active" : "nav-link omat" }>{t('nav-start')}</NavLink>
            <NavLink to="/recipe" className={({ isActive }) => isActive ? "nav-link kirja active" : "nav-link kirja" }>{t('nav-recipe')}</NavLink>
            <NavLink to="/list" className={({ isActive }) => isActive ? "nav-link hattu active" : "nav-link hattu" }>{t('nav-list')}</NavLink>
            <NavLink to="/compareweek" className={({ isActive }) => isActive ? "nav-link vertaile active" : "nav-link vertaile" }>{t('nav-compareweek')}</NavLink>
            <NavLink to="/recipes" className={({ isActive }) => isActive ? "nav-link aterimet active" : "nav-link aterimet" }>{t('nav-recipes')}</NavLink>
            <NavLink to="/materials" className={({ isActive }) => isActive ? "nav-link vaaka active" : "nav-link vaaka" }>{t('nav-materials')}</NavLink>
        </div>
    )
}
export default Navigation;