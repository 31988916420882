import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    DndContext, 
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {restrictToVerticalAxis} from '@dnd-kit/modifiers';
import Navigation from './Navigation';
import BaseCircleChart from './Utils/BaseCircleChart';
import RecipeMaterialRow from './Components/RecipeMaterialRow'
import SeasonMaterials from './Components/SeasonMaterials'
import erkconfig from "./Configuration"
import {getWeights, MaterialName, checkNum, roundNum, getCurrency, getMaterialPrice, getNumber, getRecipePortionPrice, getRecipeEkoPros, getPortionWeight, getNutritiondata, getRealMaterial} from './Utils/RecipeTools'

const defaultRecipe = {
    "NAME" : "",
    "PORTIONS" : 1,
    "TYPE" : "Pääruoka",
    "PUBLISH" : "Oma",
    "MATERIALS" : [],
    "COOKINGLOSSLESS": 0,
    "COMMENTS" : "",
    "USERID" : "",
    "cansave": true
}

const Recipe = ({ children }) => {

    const { t } = useTranslation();

    const token = localStorage.getItem('t');
    let params = useParams();

    const [newRecipe, setNewRecipe] = React.useState(true);
    const [saved, setSaved] = React.useState(false);
    const [recipe, setRecipe] = React.useState(defaultRecipe);
    const [portionWeight, setPortionWeight] = React.useState(1);
    const [ekoPros, setEkoPros] = React.useState(0);
    const [portionPrice, setPortionPrice] = React.useState(0.00);
    const [totalweight, setTotalweight] = React.useState(0);
    const [totallossless, setTotallossless] = React.useState(0);
    const [totallosslesspros, setTotallosslesspros] = React.useState(0);
    const [nutritionData, setNutritionData] = React.useState({
        kcal: 0,
        kj: 0,
        rasva: 0,
        rasvapros: 0,
        rasvatyyd: 0,
        rasvatyydpros: 0,
        hiilihydraatit: 0,
        hiilihydraatitpros: 0,
        hiilihydraatitsok: 0,
        hiilihydraatitsokpros: 0,
        proteiini: 0,
        proteiinipros: 0,
        ravintokuitu: 0,
        natrium: 0,
        suola: 0,
        avit: 0,
        dvit: 0,
        cvit: 0,
        b1: 0,
        folaatti: 0
    });
    const [addMaterialDialogVisible, setAddMaterialDialogVisible] = React.useState(false);
    const [filterStr, setFilterStr] = React.useState("");
    const [filteredMaterials, setFilteredMaterials] = React.useState([]);
    const [ekoProsUsePrice, setEkoProsUsePrice] = React.useState(false);
    const [energyUsePortion, setEnergyUsePortion] = React.useState(true);
    const [beforeEditPortionCount, setBeforeEditPortionCount] = React.useState(1);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const showAddMaterialDialog = () => {
        setFilterStr("");
        setAddMaterialDialogVisible(true);
    }

    const handleDragEnd = (event) => {
        //console.log(event)
        const {active, over} = event;
        if (active.id !== over.id) {
            let om = [...recipe.MATERIALS];
            const oldIndex = om.findIndex(item => item.uid === active.id);
            const newIndex = om.findIndex(item => item.uid === over.id);
            om = arrayMove(om, oldIndex, newIndex);
            // update MORDER int to store order
            for (let m=0 ; m < om.length ; m++) {
                om[m].MORDER = m;
                //console.log(m + ': ' + om[m].NAME)
            }
            setRecipe({
                ...recipe,
                ["MATERIALS"]: om
            });
        }
    }

    const handleChange = (event) => {
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setRecipe({
			...recipe,
			[field]: value
		});
    }

    const handlePortionsFocus = () => {
        setBeforeEditPortionCount(checkNum(recipe.PORTIONS));
    }

    const handlePortionsBlur = (event) => {
        let portions = checkNum(event.target.value)
        if (!newRecipe && beforeEditPortionCount && beforeEditPortionCount!=portions) {
            if (recipe.MATERIALS && recipe.MATERIALS.length>0 && confirm(t('recipe.confirmportionschange'))) {
                let om = [...recipe.MATERIALS];
                if (om) {
                    for (const materialindex in om) {
                        let currentWeight = checkNum(om[materialindex].WEIGHT)
                        let materialWeight = currentWeight / beforeEditPortionCount
                        let newweight = materialWeight * portions
                        om[materialindex].WEIGHT = roundNum(newweight, 0);

                        let currentLosslessWeight = checkNum(om[materialindex].LOSSLESS)
                        if (currentLosslessWeight!==undefined && currentLosslessWeight>0) {
                            let materialLossless = currentLosslessWeight / beforeEditPortionCount
                            let NewLosslessWeight = materialLossless * portions
                            om[materialindex].LOSSLESS = roundNum(NewLosslessWeight, 0);
                        }
                    }
                }
                setRecipe({
                    ...recipe,
                    ["MATERIALS"]: om
                });
            }
        }
    }

    const handleLosslesspercentCookingChange = (event) => {
        let value = event.target.value;
        //value = value.replace('.', ',');
        value = value.replace(/[^0-9]/g, '');
        if (!value) {
            //value = 0;
        }
        setRecipe({
            ...recipe,
            ["COOKINGLOSSLESS"]: value
        });
    }

    const handleMaterialChange = (event, material) => {
        let value = event.target.value;
        //value = value.replace('.', ',');
        value = value.replace(/[^0-9]/g, '');
        if (!value) {
            //value = 0;
        }

        let om = [...recipe.MATERIALS];
        let found = false;
        if (om) {
            for (const materialindex in om) {
                if (om[materialindex].uid === material.uid) {
                    //og[gradient] = selectedOrganicGradient
                    om[materialindex][event.target.id] = value

                    // if not lossless then fill it also
                    if (event.target.id=="WEIGHT") {
                        if (checkNum(om[materialindex].LOSSLESS)==0 || checkNum(om[materialindex].LOSSLESS)>checkNum(value)) {
                            //om[materialindex].LOSSLESS = value
                        }
                    }
                    if (event.target.id=="LOSSLESS") {
                        if (checkNum(om[materialindex].WEIGHT)==0 || checkNum(om[materialindex].WEIGHT)<checkNum(value)) {
                            om[materialindex].LOSSLESS = checkNum(om[materialindex].WEIGHT)
                        }
                    }

                    found = true;
                }
            }
            // if not found add clone from selected
            /*
            if (!found) {
                const clone = JSON.parse(JSON.stringify(selectedOrganicGradient));
                clone[event.target.id] = event.target.value
                og.push(clone)
            }
            */
        }
        setRecipe({
            ...recipe,
            ["MATERIALS"]: om
        });

    }

    const handleMaterialBlur = (event, material) => {
        let value = event.target.value;

        let om = [...recipe.MATERIALS];
        if (om) {
            for (const materialindex in om) {
                if (om[materialindex].uid === material.uid) {
                    om[materialindex][event.target.id] = value
                    // if not lossless then fill it also
                    if (event.target.id=="WEIGHT") {
                        if (checkNum(om[materialindex].LOSSLESS)==0 || checkNum(om[materialindex].LOSSLESS)>checkNum(value)) {
                            om[materialindex].LOSSLESS = value
                        }
                    }
                }
            }
        }
        setRecipe({
            ...recipe,
            ["MATERIALS"]: om
        });

    }
    const changeMaterialToEko = (material) => {
        let om = [...recipe.MATERIALS];
        if (om) {
            for (const materialindex in om) {
                if (om[materialindex].MATERIALID === material.MATERIALID) {
                    //console.log(material)
                    if (material.EKOMATERIAL) {
                        let realmaterial = getRealMaterial(material.EKOMATERIAL)
                        if (realmaterial) {
                            let newmaterial = JSON.parse(JSON.stringify(realmaterial));
                            let newname = realmaterial.NAME;
                            if (realmaterial.SUBNAME) {
                                newname = newname + ", " + realmaterial.SUBNAME
                            }
                            newmaterial.NAME = newname;
                            newmaterial.PRICE = realmaterial.PRICE;
                            newmaterial.EKO = realmaterial.EKO;
                            newmaterial.WEIGHT = material.WEIGHT;
                            newmaterial.LOSSLESS = material.LOSSLESS;
                            newmaterial.MORDER = material.MORDER;
                            newmaterial.MATERIALID = realmaterial.id;

                            // Nutrition data 
                            newmaterial.kcal = realmaterial.KCAL;
                            newmaterial.kj = realmaterial.KJ;
                            newmaterial.rasva = realmaterial.RASVA;
                            newmaterial.rasvatyyd = realmaterial.RASVA_TYYDYTTYNYT;
                            newmaterial.hiilihydraatit = realmaterial.HIILIHYDRAATIT;
                            newmaterial.hiilihydraatitsok = realmaterial.HIILIHYDRAATIT_SOKERI;
                            newmaterial.proteiini = realmaterial.PROTEIINI;
                            newmaterial.ravintokuitu = realmaterial.HIILIHYDRAATIT_KUITU;
                            newmaterial.natrium = realmaterial.NATRIUM;
                            newmaterial.suola = realmaterial.SUOLA;
                            newmaterial.avit = realmaterial["A-VITAMIINI"];
                            newmaterial.dvit = realmaterial["D-VITAMIINI"];
                            newmaterial.cvit = realmaterial["C-VITAMIINI"];
                            newmaterial.b1 = realmaterial["B1-VITAMIINI"];
                            newmaterial.folaatti = realmaterial.FOLAATTI;

                            // set original to enable go back to recipe's original material (not realmaterial's original)
                            newmaterial.ORIGINALMATERIALID = material.MATERIALID;
                            om[materialindex] = newmaterial;
                        }
                    } else if (material.ORIGINALMATERIALID) {
                        let realmaterial = getRealMaterial(material.ORIGINALMATERIALID)
                        if (realmaterial) {
                            let newmaterial = JSON.parse(JSON.stringify(realmaterial));
                            let newname = realmaterial.NAME;
                            if (realmaterial.SUBNAME) {
                                newname = newname + ", " + realmaterial.SUBNAME
                            }
                            newmaterial.NAME = newname;
                            newmaterial.PRICE = realmaterial.PRICE;
                            newmaterial.EKO = realmaterial.EKO;
                            newmaterial.WEIGHT = material.WEIGHT;
                            newmaterial.LOSSLESS = material.LOSSLESS;
                            newmaterial.MORDER = material.MORDER;
                            newmaterial.MATERIALID = realmaterial.id;
                            // Nutrition data 
                            newmaterial.kcal = realmaterial.KCAL;
                            newmaterial.kj = realmaterial.KJ;
                            newmaterial.rasva = realmaterial.RASVA;
                            newmaterial.rasvatyyd = realmaterial.RASVA_TYYDYTTYNYT;
                            newmaterial.hiilihydraatit = realmaterial.HIILIHYDRAATIT;
                            newmaterial.hiilihydraatitsok = realmaterial.HIILIHYDRAATIT_SOKERI;
                            newmaterial.proteiini = realmaterial.PROTEIINI;
                            newmaterial.ravintokuitu = realmaterial.HIILIHYDRAATIT_KUITU;
                            newmaterial.natrium = realmaterial.NATRIUM;
                            newmaterial.suola = realmaterial.SUOLA;
                            newmaterial.avit = realmaterial["A-VITAMIINI"];
                            newmaterial.dvit = realmaterial["D-VITAMIINI"];
                            newmaterial.cvit = realmaterial["C-VITAMIINI"];
                            newmaterial.b1 = realmaterial["B1-VITAMIINI"];
                            newmaterial.folaatti = realmaterial.FOLAATTI;

                            om[materialindex] = newmaterial;
                        }
                    }
                }
            }
        }
        setRecipe({
            ...recipe,
            ["MATERIALS"]: om
        });
    }

    const loadMaterials = () => {
        setFilteredMaterials(null)
        let params = {};
        if (filterStr) {
            params.filter = filterStr
        }
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setFilteredMaterials(response.data.materials)
            }
        })
        .catch(function(error) {
        });
    }

    const addMaterial = (material) => {
        let params = {
            token: token,
            materialid: material.id
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=get', params)
        .then(function (response) {
            if (response.data.success) {
                let addedmaterial = response.data.material;
                addedmaterial.WEIGHT = 0;
                addedmaterial.MORDER = recipe.MATERIALS.length+1;
                addedmaterial.LOSSLESS = 0;
                addedmaterial.uid = uid();
                let om = [...recipe.MATERIALS];
                om.push(addedmaterial);
                setRecipe({
                    ...recipe,
                    ["MATERIALS"]: om
                });
            }
        })
        .catch(function(error) {
        });
        setAddMaterialDialogVisible(false)
    }

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }

    const uid = function(){
        return Date.now().toString(36) + Math.random().toString(36).substr(2);
    }

    const deleteMaterialRow = (event, material) => {
        event.preventDefault();

        let om = [...recipe.MATERIALS];
        let found = -1;
        if (om) {
            for (const materialindex in om) {
                if (om[materialindex].uid === material.uid) {
                    found = materialindex;
                }
            }
            if (found) {
                om.splice(found, 1);
            }
        }
        setRecipe({
            ...recipe,
            ["MATERIALS"]: om
        });
    }


    const saveRecipe = () => {
        setSaved(false)
        let params = {
            token: token,
            id: recipe.id,
            recipe: recipe
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=save', params)
        .then(function (response) {
            if (response.data.success) {
                setSaved(true)
                setRecipe({
                    ...recipe,
                    ["id"]: response.data.recipe.id,
                    ["cansave"]: true
                });
            }
        })
        .catch(function(error) {
        });

    }

    const saveRecipeAs = () => {
        setSaved(false)
        let params = {
            token: token,
            recipe: recipe
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=save', params)
        .then(function (response) {
            if (response.data.success) {
                setSaved(true)
                setRecipe({
                    ...recipe,
                    ["id"]: response.data.recipe.id,
                    ["cansave"]: true
                });
            }
        })
        .catch(function(error) {
        });

    }

    useEffect(() => {
		if (params.recipeid) {
            let postparams = {
                token: token,
                recipeid: params.recipeid
            };
            setNewRecipe(false);
			setRecipe(defaultRecipe)
            axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=get', postparams)
            .then(function (response) {
                if (response.data.success) {
                    let recipe = response.data.recipe;

                    // sort materials to manual order
                    let materials = recipe.MATERIALS;
                    //materials.sort((a,b) => a.MORDER - b.MORDER);
                    materials.forEach((material) => {
                        material.uid = uid();
                        if (material.WEIGHT) {
                            let rowweight = checkNum(material.WEIGHT);
                            if (checkNum(material.LOSSLESS)==0 || checkNum(material.LOSSLESS)>rowweight) {
                                material.LOSSLESS = rowweight
                            }
                        }
                    });
                    recipe.MATERIALS = materials;
                    if (!recipe.COOKINGLOSSLESS) {
                        recipe.COOKINGLOSSLESS = 0;
                    }
                    setRecipe(recipe)
                }
            })
            .catch(function(error) {
            });
		}
	}, [params.recipeid])

    useEffect(() => {
        loadMaterials();
	}, [filterStr])

    useEffect(() => {
		if (recipe) {
            setEkoPros(getRecipeEkoPros(recipe, ekoProsUsePrice));
            setPortionPrice(getRecipePortionPrice(recipe));
            setPortionWeight(getPortionWeight(recipe, recipe.COOKINGLOSSLESS));
            if (portionWeight) {
                setNutritionData(getNutritiondata(recipe, portionWeight, energyUsePortion))
            }
            // calc totalweight and lossless weight
            let weights = getWeights(recipe, recipe.COOKINGLOSSLESS);
            setTotalweight(weights.total)
            setTotallossless(weights.lossless)
            setTotallosslesspros(weights.losslesspercent)
		}
	}, [recipe, ekoProsUsePrice, energyUsePortion])

    useEffect(() => {
		if (portionWeight) {
            setNutritionData(getNutritiondata(recipe, portionWeight, energyUsePortion))
		}
	}, [portionWeight])

	return (
        <>

            <div className={addMaterialDialogVisible ? "modal fade show d-block" : "modal fade"} id="addMaterialDialog" tabIndex="-1" aria-labelledby="addMaterialDialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header flex-column pb-0">
                            <div className="d-flex w-100">
                                <div className="input-group mb-2 pe-2">
                                    <input ref={input => input && input.focus()} type="text" className="form-control" placeholder={t('recipe.search-placeholder')} aria-label={t('recipe.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                                    <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                                </div>
                                <button type="button" className="btn-close mb-2" aria-label="Close" onClick={() => setAddMaterialDialogVisible(false)}></button>
                            </div>

                            <div className="w-100">
                                <div className="row table-header small no-border px-2">
                                    <div className="col-8">{t('recipe.materials-name')}</div>
                                    <div className="col-2 justify-content-center">{t('recipe.materials-eko')}</div>
                                    <div className="col-2 justify-content-end">{t('recipe.materials-price')}</div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-body pt-0 px-4" style={{minHeight: "512px", maxHeight: "512px"}}>
                            {filteredMaterials &&
                            <>
                                {filteredMaterials.map((material) =>
                                <div key={material.id} className="row table-row small cursor-pointer" onClick={() => addMaterial(material)}>
                                    <div className="col-8 br-d justify-content-start"><MaterialName {...material} /></div>
                                    <div className="col-2 br-d justify-content-center">{material.EKO ? <i className="bi bi-check-circle-fill ekomark eko"></i> : <i className="bi bi-check-circle-fill ekomark"></i>}</div>
                                    <div className="col-2 justify-content-end">{getCurrency(getMaterialPrice(material))}&euro;</div>
                                </div>
                                )}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {(addMaterialDialogVisible) &&
                <div className="modal-backdrop fade show"></div>
            } 

            <SeasonMaterials addMaterial={addMaterial} />

            <Navigation />

            <div className="row">
                <div className="col-md-8">
                    <div className="content-box">

                        {newRecipe ? (

                            <>
                                <h2>{t('recipe.new-title')}</h2>
                                <p>{t('recipe.new-desc')}</p>

                                <div className="mb-3">
                                    <label htmlFor="recipe-name" className="form-label">{t('recipe.name')}</label>
                                    <input type="text" className="form-control" id="NAME" value={recipe.NAME} onChange={handleChange}/>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="mb-3">
                                            <label htmlFor="recipe-portions" className="form-label">{t('recipe.portions')}</label>
                                            <input type="text" className="form-control" id="PORTIONS" value={recipe.PORTIONS} onChange={handleChange} />
                                        </div>
                                    </div>
                                </div>

                                <button className="btn btn-secondary" onClick={() => setNewRecipe(false)}>{t('recipe.new-continue')}</button>
                            
                            </>

                        ) : (
                            <>
                            <h2>{t('recipe.title')}{recipe.NAME && ' - ' + recipe.NAME}</h2>

                            {saved &&
                               <div className="alert alert-success" role="alert">{t('recipe.saved')}</div>
                            }

                            <div className="mb-3">
                                <label htmlFor="recipe-name" className="form-label">{t('recipe.name')}</label>
                                <input type="text" className="form-control" id="NAME" value={recipe.NAME} onChange={handleChange}/>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label htmlFor="recipe-portions" className="form-label">{t('recipe.portions')}</label>
                                        <input type="text" className="form-control" id="PORTIONS" value={recipe.PORTIONS} onChange={handleChange} onBlur={handlePortionsBlur} onFocus={handlePortionsFocus}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label htmlFor="recipe-portionsize" className="form-label">{t('recipe.portionsize')}</label>
                                        <input type="text" className="form-control-plaintext" readOnly={true} id="recipe-portionsize" value={portionWeight} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label htmlFor="recipe-type" className="form-label">{t('recipe.type')}</label>
                                        <select id="TYPE" className="form-select" value={recipe.TYPE} onChange={handleChange}>
                                            <option value="Pääruoka">{t('recipe.type-main')}</option>
                                            <option value="Kasvispääruoka">{t('recipe.type-vege')}</option>
                                            <option value="Aterian lisäke">{t('recipe.type-addon')}</option>
                                            <option value="Välipala">{t('recipe.type-middle')}</option>
                                            <option value="Lisäkesalaatti">{t('recipe.type-salad')}</option>
                                            <option value="Jälkiruoka">{t('recipe.type-after')}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label htmlFor="recipe-publish" className="form-label">{t('recipe.publish')}</label>
                                        <select id="PUBLISH" className="form-select" value={recipe.PUBLISH} onChange={handleChange}>
                                            <option value="Oma">{t('recipe.publish-own')}</option>
                                            <option value="Julkinen">{t('recipe.publish-public')}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="row table-header small align-items-end">
                                <div className="col-3 titlehead">{t("recipe.materials-name")}</div>
                                <div className="col-1 br-d justify-content-center">{t("recipe.materials-info")}</div>
                                <div className="col-2 br-d justify-content-center">{t("recipe.materials-rawweight")}</div>
                                <div className="col-2 br-d justify-content-center">{t("recipe.materials-weight")}</div>
                                <div className="col-1 br-d justify-content-center" dangerouslySetInnerHTML={{__html: t('recipe.materials-price')}}></div>
                                <div className="col-1 br-d justify-content-center">{t("recipe.materials-eko")}</div>
                                <div className="col-1 br-d justify-content-center" dangerouslySetInnerHTML={{__html: t('recipe.materials-comments')}}></div>
                                <div className="col-1 justify-content-center">{t("recipe.materials-delete")}</div>
                            </div>

                            <DndContext 
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                modifiers={[restrictToVerticalAxis]}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext 
                                    items={recipe.MATERIALS.map((i) => i?.uid)}
                                    strategy={verticalListSortingStrategy}>
                                    {recipe.MATERIALS.map((material, mi) => (
                                         <RecipeMaterialRow 
                                            key={material.uid} 
                                            id={material.uid} 
                                            material={material} 
                                            handleMaterialChange={handleMaterialChange} 
                                            handleMaterialBlur={handleMaterialBlur}
                                            changeMaterialToEko={changeMaterialToEko} 
                                            deleteMaterialRow={deleteMaterialRow} />
                                    ))}
                                </SortableContext>
                            </DndContext>

                            <div className="mt-2 mb-3">
                                {recipe.MATERIALS.length==0 &&
                                    <p className="text-center small"><i>{t('recipe.no-materials')}</i></p>
                                }
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary" type="button" onClick={() => showAddMaterialDialog()}>{t("recipe.add-material")}</button>
                                </div>
                            </div>

                            <div className="mt-2 mb-3">
                                <div className="row small">
                                    <div className="col-md-3 py-2 fst-italic" style={{borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd",}}>Raakapaino yht. <span id="totalweight">{getNumber(totalweight, 0)}</span>g<br/>Saanto yht. <span id="totallossless">{getNumber(totallossless, 0)}</span>g</div>
                                    <div className="col-md-6 py-2 fst-italic align-items-center" style={{borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd",}}>Kypsennyshävikki <input id="COOKINGLOSSLESS" className="form-control form-control-sm d-inline-block me-1" style={{maxWidth: "60px"}} value={recipe.COOKINGLOSSLESS} onChange={handleLosslesspercentCookingChange} maxLength="3"/>%</div>
                                    <div className="col-md-3 py-2 fst-italic" style={{borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd",}}>Valmistushävikki <span id="losslesspercent">{getNumber(totallosslesspros, 0)}</span>%</div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="recipe-notes" className="form-label">{t('recipe.guide')}</label>
                                <textarea className="form-control" id="COMMENTS" rows="10" value={recipe.COMMENTS} onChange={handleChange}></textarea>
                            </div>

                            {(!recipe.cansave) && 
                                <p className="my-2 small"><i>{t('recipe.savedAsDesc')}</i></p>
                            }

                            <button className="btn btn-secondary" onClick={saveRecipe}>{(recipe.cansave ? t('save') :t('recipe.savedAs') )}</button>
                            {(recipe.cansave) && 
                                <button className="btn btn-default ms-3" onClick={saveRecipeAs}>{t('recipe.savedAs')}</button>
                            }
                            
                            </>
                        )}

                        {saved &&
                            <div className="alert alert-success mt-3" role="alert">{t('recipe.saved')}</div>
                        }
                            
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="content-box">

                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <h4 className="detailmargin">Annoksen <br/> hinta</h4>
                            </div>
                            <div className="col-md-6">
                                <h4 className="detailmargin">Luomuprosentti<br/><span id="ekoweight" className={ekoProsUsePrice ? "unactive_type" : "active_type"} onClick={(() => setEkoProsUsePrice(false))}>Kilo</span> / <span id="ekoprice" className={ekoProsUsePrice ? "active_type" : "unactive_type"} onClick={(() => setEkoProsUsePrice(true))}>Hinta</span> </h4>
                            </div>
                        </div>		

                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <div className="bluebox box"><h4 id="portionprice">{portionPrice}&euro;</h4></div>
                            </div>
                            <div className="col-md-6">
                                <div className="greenbox box"><h4 id="ekopros">{ekoPros}%</h4></div>
                            </div>
                        </div>		


                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-xs-12">
                                <h4 className="detailmargin">Energia<br/><span id="perAnnos" className={energyUsePortion ? "active_type" : "unactive_type"} onClick={(() => setEnergyUsePortion(true))}>per annos</span> / <span id="perSata" className={energyUsePortion ? "unactive_type" : "active_type"} onClick={(() => setEnergyUsePortion(false))}>per 100g</span> </h4>
                            </div>
                        </div>	

                        <div className="row">
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <div className="greybox box">
                                    <h4 id="portionkcal">{getNumber(nutritionData.kcal, 0)} <span className="small">kcal</span></h4>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="graph" className="graphcontainer" style={{width: "145px", height: "140px"}}>
                                    <BaseCircleChart stats={{
                                        rasva: nutritionData.rasvapros, 
                                        hiilihydraatit: nutritionData.hiilihydraatitpros, 
                                        proteiinit: nutritionData.proteiinipros
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <h4 className="detailmargin">Ravintosisältö</h4>
                                <table className="table nutritiontable">
                                    <tbody>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#989484"}}></div></td>
                                            <td>rasva</td>
                                            <td className="text-end"><span id="rasva_g">{getNumber(nutritionData.rasva)}</span>g</td>
                                            <td className="text-end"><span id="rasva_p">{getNumber(nutritionData.rasvapros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><small>- josta tyydyttynyttä</small></td>
                                            <td className="text-end"><span id="rasvatyyd_g">{getNumber(nutritionData.rasvatyyd)}</span>g</td>
                                            <td className="text-end"><span id="rasvatyyd_p">{getNumber(nutritionData.rasvatyydpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#B17F1F"}}></div></td>
                                            <td>hiilihydraatit</td>
                                            <td className="text-end"><span id="hiilihydraatit_g">{getNumber(nutritionData.hiilihydraatit)}</span>g</td>
                                            <td className="text-end"><span id="hiilihydraatit_p">{getNumber(nutritionData.hiilihydraatitpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><small>- josta sokereita</small></td>
                                            <td className="text-end"><span id="hiilihydraatitsok_g">{getNumber(nutritionData.hiilihydraatitsok)}</span>g</td>
                                            <td className="text-end"><span id="hiilihydraatitsok_p">{getNumber(nutritionData.hiilihydraatitsokpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#E8901F"}}></div></td>
                                            <td>proteiini</td>
                                            <td className="text-end"><span id="proteiini_g">{getNumber(nutritionData.proteiini)}</span>g</td>
                                            <td className="text-end"><span id="proteiini_p">{getNumber(nutritionData.proteiinipros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>ravintokuitu</td>
                                            <td className="text-end"><span id="ravintokuitu_g">{getNumber(nutritionData.ravintokuitu)}</span>g</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>suola</td>
                                            <td className="text-end"><span id="suola_g">{getNumber(nutritionData.suola)}</span>g</td>
                                            <td className="text-end"></td>
                                        </tr>						
                                        <tr>
                                            <td></td>
                                            <td>A-vit</td>
                                            <td className="text-end"><span id="avit_g">{getNumber(nutritionData.avit)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>D-vit</td>
                                            <td className="text-end"><span id="dvit_g">{getNumber(nutritionData.dvit)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>C-vit</td>
                                            <td className="text-end"><span id="cvit_g">{getNumber(nutritionData.cvit)}</span>mg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>B1 tiamiini</td>
                                            <td className="text-end"><span id="b1_g">{getNumber(nutritionData.b1)}</span>mg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>folaatti</td>
                                            <td className="text-end"><span id="folaatti_g">{getNumber(nutritionData.folaatti)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>		

                    </div>
                </div>
            </div>
        </>
    )
}
export default Recipe;