import React, {useEffect} from 'react'
import { useTranslation } from "react-i18next";

const Pagination = ({nPages, currentPage, setCurrentPage }) => {

    const { t, i18n } = useTranslation();

    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
    //console.log("pageArr " + [...Array(nPages + 1).keys()])
    //console.log("pageNumbers " + pageNumbers)

    const [pageNumbers, setPageNumbers] = React.useState(null);
    const [init, setInit] = React.useState(false);

    const firstPage = (event) => {
        event.preventDefault();
        setCurrentPage(1)
    }
    const nextPage = (event) => {
        event.preventDefault();
        if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = (event) => {
        event.preventDefault();
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    const lastPage = (event) => {
        event.preventDefault();
        setCurrentPage(nPages)
    }
    const gotoPage = (event, pagenumber) => {
        event.preventDefault();
        setCurrentPage(pagenumber)
    }

    useEffect(() => {
		if (nPages) {
            //let pageArr = [...Array(nPages + 1).keys()].slice(1);
            let pageArr = [];
            for (let i = 0; i < nPages; i++) {
                pageArr.push(i+1)
            }
            console.log("nPages " + nPages)
            //console.log("currentPage " + currentPage)
            console.log(pageArr)
            setPageNumbers(pageArr)
            setInit(true)
		}
	}, [nPages])

    useEffect(() => {
		if (pageNumbers) {
            console.log("useEffect pageNumbers " + pageNumbers)
		}
	}, [pageNumbers])


    return (
        <>
            {nPages>1 &&
                <nav>
                    <ul className='pagination justify-content-center'>
                        {(currentPage>5) &&
                        <li className="page-item">
                            <a className="page-link" onClick={firstPage} href='#'>{t("pagination.first")}</a>
                        </li>
                        }
                        {(currentPage!==1) &&
                        <li className="page-item">
                            <a className="page-link" onClick={prevPage} href='#'>
                                <span aria-hidden="true">&laquo;</span>
                            </a>
                        </li>
                        }
                        {pageNumbers &&
                        <>
                            {pageNumbers.map(pgNumber => (
                                <React.Fragment key={pgNumber}>
                                    {(currentPage>pgNumber-5 && currentPage<pgNumber+5) &&
                                    <li className={`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                                        <a onClick={(event) => gotoPage(event, pgNumber)}  
                                            className='page-link' 
                                            href='#'>
                                            {pgNumber}
                                        </a>
                                    </li>
                                    }
                                </React.Fragment>
                            ))}
                        </>
                        }
                        
                        {(currentPage!==nPages) &&
                        <li className="page-item">
                            <a className="page-link" onClick={nextPage} href='#'>
                                <span aria-hidden="true">&raquo;</span>
                            </a>
                        </li>
                        }
                        {(currentPage<nPages-5) &&
                        <li className="page-item">
                            <a className="page-link" onClick={lastPage} href='#'>{t("pagination.last")}</a>
                        </li>
                        }
                    </ul>
                </nav>
            }
        
        </>
        
    )
}

export default Pagination