import React from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import './i18n';
import './css/style.css'
import Ekoruokakone from './App.js';

const container = document.getElementById('ekoruokakone-app');
const root = createRoot(container); 
root.render(
	<Ekoruokakone />
);