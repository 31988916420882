import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fi from 'date-fns/locale/fi';
import Navigation from './Navigation';
import BaseCircleChart from './Utils/BaseCircleChart';
import erkconfig from "./Configuration"
import {getWeights, getPrices, MaterialName, checkNum, roundNum, getCurrency, getNumber, getRecipePortionPrice, getRecipeEkoPros, getPortionWeight, getNutritiondata} from './Utils/RecipeTools'
import toDate from 'date-fns/toDate/index';

const defaultnd = {
    kcal: 0,
    kj: 0,
    rasva: 0,
    rasvapros: 0,
    rasvatyyd: 0,
    rasvatyydpros: 0,
    hiilihydraatit: 0,
    hiilihydraatitpros: 0,
    hiilihydraatitsok: 0,
    hiilihydraatitsokpros: 0,
    proteiini: 0,
    proteiinipros: 0,
    ravintokuitu: 0,
    natrium: 0,
    suola: 0,
    avit: 0,
    dvit: 0,
    cvit: 0,
    b1: 0,
    folaatti: 0,
    totalweight: 0
}

const List = ({ children }) => {

    const { t } = useTranslation();
    const token = localStorage.getItem('t');

    moment.locale("fi")
    registerLocale("fi", fi)

    const [filterStr, setFilterStr] = React.useState("");
    const [filteredRecipes, setFilteredRecipes] = React.useState([]);
    const [filterOwn, setFilterOwn] = React.useState(false);
    const [filterModel, setFilterModel] = React.useState(false);
    const [filterMaincourse, setFilterMaincourse] = React.useState(false);
    const [filterVege, setFilterVege] = React.useState(false);
    const [filterSalad, setFilterSalad] = React.useState(false);
    const [filterOthers, setFilterOthers] = React.useState(false);
    const [filterAddon, setFilterAddon] = React.useState(false);
    const [filterMiddle, setFilterMiddle] = React.useState(false);
    const [filterDessert, setFilterDessert] = React.useState(false);
    
    const [sortby, setSortBy] = React.useState("NAME");
    const [sort, setSort] = React.useState("asc");
    const [changedRecipeId, setChangedRecipeId] = React.useState(null);
    const [portionWeight, setPortionWeight] = React.useState(1);
    const [ekoPros, setEkoPros] = React.useState(0);
    const [portionPrice, setPortionPrice] = React.useState(0.00);
    const [totalweight, setTotalweight] = React.useState(0);
    const [totallossless, setTotallossless] = React.useState(0);
    const [totallosslesspros, setTotallosslesspros] = React.useState(0);
    const [losslesspercentCooking, setLosslesspercentCooking] = React.useState(0);
    const [nutritionData, setNutritionData] = React.useState(JSON.parse(JSON.stringify(defaultnd)));
    const [addRecipeDialogVisible, setAddRecipeDialogVisible] = React.useState(false);
    const [weeksWithRecipes, setWeeksWithRecipes] = React.useState(null);
    const [weekData, setWeekData] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState({
        date: moment(),
        week: moment().week(),
        weekstr: moment().week() + "|" + moment().year(),
        year: moment().year()
    });
    const [calendarHighlightDates, setCalendarHighlightDates] = React.useState();
    const [copyWeekModalVisible, setCopyWeekModalVisible] = React.useState(false);
    const [copyWeekFrom, setCopyWeekFrom] = React.useState(null);
    const [copyWeekTo, setCopyWeekTo] = React.useState(null);
    const [ekoProsUsePrice, setEkoProsUsePrice] = React.useState(false);
    const [energyUsePortion, setEnergyUsePortion] = React.useState(true);

    const handleWeekChange = (event) => {
        let value = event.target.value;
        let week = value.split('|')[0];
        let year = value.split('|')[1];
        let newdate = moment().isoWeekYear(year).isoWeek(week).startOf('week')
        setSelectedDate({
            date: newdate,
            week: checkNum(week),
            weekstr: value,
            year: checkNum(year),
        });
    }

    const handleDateChange = (date) => {
        let newdate = moment(date, 'YYYYMMDD')
        let week = newdate.week()
        let year = newdate.year()
        setSelectedDate({
            date: newdate,
            week: checkNum(week),
            weekstr: newdate.week() + "|" + newdate.year(),
            year: checkNum(year),
        });
    }

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }

    const handleWeekFromChange = (event) => {
        let value = event.target.value;
        setCopyWeekFrom(value);
    }
    const handleWeekToChange = (event) => {
        let value = event.target.value;
        setCopyWeekTo(value);
    }

    const handleFilterCheckChange = (event) => {
        let field = event.target.id;
        if (field=='filterOwn') {
            setFilterOwn(!filterOwn);
        } else if (field=='filterModel') {
            setFilterModel(!filterModel);
        } else if (field=='filterMaincourse') {
            setFilterMaincourse(!filterMaincourse);
        } else if (field=='filterVege') {
            setFilterVege(!filterVege);
        } else if (field=='filterSalad') {
            setFilterSalad(!filterSalad);
        } else if (field=='filterOthers') {
            setFilterOthers(!filterOthers);
        } else if (field=='filterAddon') {
            setFilterAddon(!filterAddon);
        } else if (field=='filterMiddle') {
            setFilterMiddle(!filterMiddle);
        } else if (field=='filterDessert') {
            setFilterDessert(!filterDessert);
        }
    }


    const deleteRecipeRow = (event, weekrecipewitem) => {
        event.preventDefault();
        let params = {
            token: token,
            weekrecipewitemid: weekrecipewitem.itemid
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=deleterecipe', params)
        .then(function (response) {
            if (response.data.success) {
                loadWeek();
            }
        })
        .catch(function(error) {
        });
    }
    const clearToday = (event, date) => {
        event.preventDefault();
        handleDateChange(date)
        if (confirm(t('lists.cleartodaylinkconfirm'))) {
            let params = {
                token: token,
                week: selectedDate.date.format('W'),
                datestr: date
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=clearday', params)
            .then(function (response) {
                if (response.data.success) {
                    loadWeek();
                }
            })
            .catch(function(error) {
            });
        }
        
    }

    const addRecipeRow = (event, date) => {
        event.preventDefault();
        if (!date) {
            date = selectedDate.date.format('yyyyMMDD');
        }
        setFilterStr("");
        handleDateChange(date);
        setAddRecipeDialogVisible(true)
    }
    const addRecipe = (recipe) => {
        let params = {
            token: token,
            recipeid: recipe.id,
            week: selectedDate.date.format('W'),
            datestr: selectedDate.date.format('yyyyMMDD')
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=addrecipe', params)
        .then(function (response) {
            if (response.data.success) {
                loadWeek();
                setAddRecipeDialogVisible(false)
                loadWeeksWithRecipes();
            }
        })
        .catch(function(error) {
        });
    }
    const changeRecipeRow = (event, date, recipeid) => {
        event.preventDefault();
        handleDateChange(date);
        setChangedRecipeId(recipeid)
        setAddRecipeDialogVisible(true)
    }
    const changeRecipe = (recipe) => {
        let params = {
            token: token,
            recipeid: changedRecipeId,
            newrecipeid: recipe.id,
            week: selectedDate.date.format('W'),
            datestr: selectedDate.date.format('yyyyMMDD')
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=changerecipe', params)
        .then(function (response) {
            if (response.data.success) {
                setChangedRecipeId(null)
                loadWeek();
                setAddRecipeDialogVisible(false)
            }
        })
        .catch(function(error) {
            setChangedRecipeId(null)
        });
    }

    const WeekDay = ({daynro}) => {
        let thisday = selectedDate.date.clone().weekday(daynro);
        let thisdaystr = thisday.format('YYYYMMDD');
        let selectedstr = selectedDate.date.format('yyyyMMDD');

        let thisdayrecipes = []
        if (weekData && weekData.recipes && weekData.recipes.length>0) {
            weekData.recipes.forEach((weekrecipewitem) => {
                if (thisday.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                    thisdayrecipes.push(weekrecipewitem)
                }
            });
        }
        // sort by type
        thisdayrecipes.sort((a,b) => (a.type > b.type) ? -1 : ((b.type > a.type) ? 1 : 0))

        return (
            <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                <div className="col-md-1" style={{borderRight: "1px solid #ddd"}}>
                    {(thisday.format('yyyyMMDD') == selectedstr) 
                    ? <h3 className="daytitle active"><i className="bi bi-check-square"></i></h3>
                    : <h3 className="daytitle" onClick={() => handleDateChange(thisdaystr)}><i className="bi bi-square"></i></h3>
                    }
                </div>
                <div className="col-md-11 ps-3 pb-3">
                    {(thisday.format('yyyyMMDD') == selectedstr) 
                    ? <h3 className="daytitle active">{thisday.format('dddd')} - {thisday.format('D.M.yyyy')}</h3>
                    : <h3 className="daytitle" onClick={() => handleDateChange(thisdaystr)}>{thisday.format('dddd')} - {thisday.format('D.M.yyyy')}</h3>
                    }

                    {(thisdayrecipes.length>0) 
                    ? (
                        <>
                        <div className="row table-header small align-items-end">
                            <div className="col-4">{t("lists.table-recipe")}</div>
                            <div className="col-2 br-d">{t("lists.table-recipetype")}</div>
                            <div className="col-1 br-d justify-content-center">{t("lists.table-changerecipe")}</div>
                            <div className="col-2 br-d justify-content-center">{t("lists.table-headeprice")}</div>
                            <div className="col-2 br-d justify-content-center">{t("lists.table-headeeko")}</div>
                            <div className="col-1 justify-content-center">{t("lists.table-delete")}</div>
                        </div>
                        {thisdayrecipes.map((weekrecipewitem, wr) => (
                            <React.Fragment key={weekrecipewitem.timestamp + wr}>
                            {(thisday.format('DD.MM.yyyy') == weekrecipewitem.timestamp) &&
                                <div key={weekrecipewitem.timestamp} className="row table-row small">
                                    <div className="col-4">{weekrecipewitem.name}</div>
                                    <div className="col-2 br-d">{weekrecipewitem.type}</div>
                                    <div className="col-1 br-d justify-content-center">
                                        <img className="popover__trigger cursor-pointer" src={erkconfig.BASEURL + "/resources/images//check_change.png"} alt={t('lists.changerecipe')} title={t('lists.changerecipe')} onClick={(event) => changeRecipeRow(event, thisdaystr, weekrecipewitem.recipe.id)} />
                                    </div>
                                    <div className="col-2 br-d justify-content-center">{getRecipePortionPrice(weekrecipewitem.recipe)}&euro;</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipeEkoPros(weekrecipewitem.recipe)}%</div>
                                    <div className="col-1 justify-content-center"><a href="#" onClick={(event) => deleteRecipeRow(event, weekrecipewitem)}><i className="bi bi-x-circle-fill checkmark-dark small"></i></a></div>
                                </div>
                            }
                            </React.Fragment>
                        ))}
                        <div className="d-flex py-2">
                            <div className="flex-grow-1">
                                <a className="btn btn-secondary btn-sm mt-1 me-2" href="#" onClick={(event) => addRecipeRow(event, thisdaystr)}>{t("lists.addrecipetodaylink")}</a>
                                <a className="btn btn-default btn-sm mt-1" href={erkconfig.BASEURL + "/apps/ruokalistapaiva-tulostus-pdf.groovy?day=" + thisdaystr + "&token=" + token} target="_blank">{t("lists.printtodaylink")}</a>
                            </div>
                            <a className="btn btn-default link-warning btn-sm mt-1" href="#" onClick={(event) => clearToday(event, thisdaystr)}>{t("lists.cleartodaylink")}</a>
                        </div>
                        </>
                    ) : (
                        <>
                            <p className="small">{t("lists.addrecipetoday")} <a href="#" onClick={(event) => addRecipeRow(event, thisdaystr)}>{t("lists.addrecipetodaylink")}</a></p>
                        </>
                    )
                    }
                </div>
            </div>	
        )
    }

    const WeeksNearBy = () => {
        let thisday = moment();
        let weeks = []
        for (var i = 0; i < 52; i++) {
            let weekdate = thisday.subtract(1, 'weeks');
            let weekObj = {
                id: weekdate.format('YYYYMMDD'),
                week: weekdate.format('W'),
                year: weekdate.format('YYYY')
            }
            weeks.push(weekObj)
        } 
        thisday = moment();
        for (var i = 0; i < 52; i++) {
            let weekdate = thisday.add(1, 'weeks');
            let weekObj = {
                id: weekdate.format('YYYYMMDD'),
                week: weekdate.format('W'),
                year: weekdate.format('YYYY')
            }
            weeks.push(weekObj)
        }

        // sort by type
        weeks.sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
        return (
            <>
            {weeks.map((weekObj, w) => (
                <option key={weekObj.id} value={weekObj.week+"|"+weekObj.year}>viikko {weekObj.week} / {weekObj.year}</option>
            ))}
            </>
        )
    }

    const loadWeeksWithRecipes = () => {
        //  weeklist
        setWeeksWithRecipes(null)
        let params = {
            token: token
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=weeklist', params)
        .then(function (response) {
            if (response.data.success) {
                setWeeksWithRecipes(response.data.weeks)

                let highlightDays = []
                if (response.data.weeks) {
                    response.data.weeks.forEach((weekrecipewitem) => {
                        let week = weekrecipewitem.VKO;
                        let year = weekrecipewitem.YEAR;
                        let days = [1, 2, 3, 4, 5, 6, 7].map(d => moment(year + "-" + week + "-" + d, 'YYYY-W-E'));
                        days.forEach((day) => {
                            highlightDays.push(day.toDate())
                        });
                    });
                }
                setCalendarHighlightDates([{"date-higligh": highlightDays}])

            }
        })
        .catch(function(error) {
        });

    };

    const loadWeek = (week, year) => {
        let weekstr = week;
        if (!week) {
            weekstr = selectedDate.date.format('W');
        }
        let yearstr = year;
        if (!year) {
            yearstr = selectedDate.date.format('YYYY');
        }
        //  weeklist
        setWeekData(null)
        let params = {
            week: weekstr,
            year: yearstr,
            token: token
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=getweek', params)
        .then(function (response) {
            if (response.data.success) {
                setWeekData(response.data.week)
                //console.log(response.data.week)
            }
        })
        .catch(function(error) {
        });

    };

    const showCopyWeek = () => {
        setCopyWeekFrom(selectedDate.date.format('W') + "|" + selectedDate.date.format('YYYY'));
        setCopyWeekModalVisible(true)
    }
    const copyWeek = () => {
        if (copyWeekFrom && copyWeekTo) {
            let weekfrom = copyWeekFrom.split('|')[0];
            let yearfrom  = copyWeekFrom.split('|')[1];
            let weekto = copyWeekTo.split('|')[0];
            let yearto  = copyWeekTo.split('|')[1];

            //  weeklist
            let params = {
                weekfrom: weekfrom,
                yearfrom: yearfrom,
                weekto: weekto,
                yearto: yearto,
                token: token
            };
            axios.post(erkconfig.BASEURL + '/apps/erk_lists?action=copyweek', params)
            .then(function (response) {
                if (response.data.success) {
                    loadWeek();
                    setCopyWeekModalVisible(false)
                    loadWeeksWithRecipes();
                }
            })
            .catch(function(error) {
            });
        }
    };

    const loadRecipes = () => {
        setFilteredRecipes(null)
        let params = {
            token: token
        };
        if (sortby) {
            params.sortby = sortby
        }
        if (sort) {
            params.sort = sort
        }
        if (filterStr) {
            params.filter = filterStr
        }
        if (filterOwn) {
            params.filterown = true
        }
        if (filterModel) {
            params.filterModel = true
        }
        if (filterMaincourse) {
            params.filterMaincourse = true
        }
        if (filterVege) {
            params.filterVege = true
        }
        if (filterSalad) {
            params.filterSalad = true
        }
        if (filterAddon) {
            params.filterAddon = true
        }
        if (filterMiddle) {
            params.filterMiddle = true
        }
        if (filterDessert) {
            params.filterDessert = true
        }
        
        axios.post(erkconfig.BASEURL + '/apps/erk_recipes?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setFilteredRecipes(response.data.recipes)
            }
        })
        .catch(function(error) {
        });
    }

    const calcNutritionData = () => {
        if (selectedDate) {

            let nd = null;
            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let pw = getPortionWeight(weekrecipewitem.recipe);
                        let ond = getNutritiondata(weekrecipewitem.recipe, pw, energyUsePortion);
                        //console.log(ond)
                        if (!nd) {
                            nd = ond;
                        } else {
                            nd.kcal = checkNum(nd.kcal) + checkNum(ond.kcal);
                            nd.kj = checkNum(nd.kj) + checkNum(ond.kj);
                            nd.rasva = nd.rasva + ond.rasva;
                            nd.rasvatyyd = checkNum(nd.rasvatyyd) + checkNum(ond.rasvatyyd);
                            nd.hiilihydraatit = checkNum(nd.hiilihydraatit) + checkNum(ond.hiilihydraatit);
                            nd.hiilihydraatitsok = checkNum(nd.hiilihydraatitsok) + checkNum(ond.hiilihydraatitsok);
                            nd.proteiini = checkNum(nd.proteiini) + checkNum(ond.proteiini);
                            nd.ravintokuitu = checkNum(nd.ravintokuitu) + checkNum(ond.ravintokuitu);
                            nd.natrium = checkNum(nd.natrium) + checkNum(ond.natrium);
                            nd.suola = checkNum(nd.suola) + checkNum(ond.suola);
                            nd.avit = checkNum(nd.avit) + checkNum(ond.avit);
                            nd.dvit = checkNum(nd.dvit) + checkNum(ond.dvit);
                            nd.cvit = checkNum(nd.cvit) + checkNum(ond.cvit);
                            nd.b1 = checkNum(nd.b1) + checkNum(ond.b1);
                            nd.folaatti = checkNum(nd.folaatti) + checkNum(ond.folaatti);
                            nd.totalweight = checkNum(nd.totalweight) + checkNum(ond.totalweight);
                        }
                        
                    }
                });
            }
            if (nd) {

                //console.log(nd.kcal + " kcal and " + nd.totalweight + " weight");

                if (energyUsePortion) {

                    if (nd.totalweight) {

                        let portions = 1;
                        let portionweight = nd.totalweight / portions;

                        // calc per portion
                        let rp = (((nd.rasva / portions) * 9) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.rasvapros = rp; }
                    
                        rp = ((nd.rasvatyyd / portions) / (nd.rasva / portions)) * 100;
                        if (!isNaN(rp)) { nd.rasvatyydpros = rp; }
                    
                        rp = (((nd.hiilihydraatit / portions) * 4) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitpros = rp; }
                
                        //rp = (nd.hiilihydraatitsok / portions) * 100 / portionweight;
                        rp = ((nd.hiilihydraatitsok / portions) / (nd.hiilihydraatit / portions)) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitsokpros = rp; }
                    
                        rp = (((nd.proteiini / portions) * 4) / (nd.kcal / portions)) * 100;
                        if (!isNaN(rp)) { nd.proteiinipros = rp; }
                        
                        /*
                        //nd.kcal = (nd.kcal / portions);
                        nd.kj = (nd.kj / portions);
                        nd.rasva = (nd.rasva / portions);
                        nd.rasvatyyd = (nd.rasvatyyd / portions);
                        nd.hiilihydraatit = (nd.hiilihydraatit / portions);
                        nd.hiilihydraatitsok = (nd.hiilihydraatitsok / portions);
                        nd.proteiini = (nd.proteiini / portions);
                        nd.ravintokuitu = (nd.ravintokuitu / portions);
                        nd.natrium = ((nd.natrium  / 1000) / portions);
                        nd.suola = ((nd.natrium  / 1000) * 2.538 / portions);
                        nd.avit = (nd.avit / portions);
                        nd.dvit = (nd.dvit / portions);
                        nd.cvit = (nd.cvit / portions);
                        nd.b1 = (nd.b1 / portions);
                        nd.folaatti = (nd.folaatti / portions);
                        */

                    }
            
                } else {

                    //console.log(nd)
                    /* NO need to do dublicate calculations
                    if (nd.totalweight) {
                        // calc per 100g
                        let kcalSata = (nd.kcal / nd.totalweight) * 100;
                        let rasvaSata = (nd.rasva / nd.totalweight) * 100;
                        let rasvatyydSata = (nd.rasvatyyd / nd.totalweight) * 100;
                        let hiilihydraattiSata = (nd.hiilihydraatit / nd.totalweight) * 100;
                        let hiilihydraattisokSata = (nd.hiilihydraatitsok / nd.totalweight) * 100;
                        let proteiiniSata = (nd.proteiini / nd.totalweight) * 100;
                        
                        let rp = ((rasvaSata * 9) / kcalSata) * 100;
                        if (!isNaN(rp)) { nd.rasvapros = rp; }
                        
                        rp = (rasvatyydSata / rasvaSata) * 100;
                        if (!isNaN(rp)) { nd.rasvatyydpros = rp; }
                    
                        rp = ((hiilihydraattiSata * 4) / kcalSata) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitpros = rp; }
                    
                        rp = (hiilihydraattisokSata / hiilihydraattiSata) * 100;
                        if (!isNaN(rp)) { nd.hiilihydraatitsokpros = rp; }
                    
                        rp = ((proteiiniSata * 4) / kcalSata) * 100;
                        if (!isNaN(rp)) { nd.proteiinipros = rp; }
                    
                        // calc weight by portion size
                        nd.kcal = (nd.kcal / nd.totalweight) * 100;
                        nd.kj = (nd.kj / nd.totalweight) * 100;
                        nd.rasva = (nd.rasva / nd.totalweight) * 100;
                        nd.rasvatyyd = (nd.rasvatyyd / nd.totalweight) * 100;
                        nd.hiilihydraatit = (nd.hiilihydraatit / nd.totalweight) * 100;
                        nd.hiilihydraatitsok = (nd.hiilihydraatitsok / nd.totalweight) * 100;
                        nd.proteiini = (nd.proteiini / nd.totalweight) * 100;
                        nd.ravintokuitu = (nd.ravintokuitu / nd.totalweight) * 100;
                        nd.natrium = (nd.natrium / nd.totalweight) * 100;
                        nd.suola = (nd.suola / nd.totalweight) * 100;
                        nd.avit = (nd.avit / nd.totalweight) * 100;
                        nd.dvit = (nd.dvit / nd.totalweight) * 100;
                        nd.cvit = (nd.cvit /nd. totalweight) * 100;
                        nd.b1 = (nd.b1 / nd.totalweight) * 100;
                        nd.folaatti = (nd.folaatti / nd.totalweight) * 100;

                    }

                    */
                }

                setNutritionData(nd);
            } else {
                setNutritionData(defaultnd);
            }
        }
    }

    const calcEkoPros = () => {
        if (selectedDate) {

            let totalprice = 0.00;
            let totalekoprice = 0.00;
            let totalweight = 0.00;
            let totalekoweight = 0.00;

            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let portions = checkNum(weekrecipewitem.recipe.PORTIONS);
                        if (!portions) {
                            portions = 1;                            
                        }
                        let prices = getPrices(weekrecipewitem.recipe);
                        if (prices) {
                            totalprice = totalprice + prices.total;
                            totalekoprice = totalekoprice + prices.eko;
                        }
                        let weights = getWeights(weekrecipewitem.recipe, weekrecipewitem.recipe.COOKINGLOSSLESS);
                        if (weights) {
                            totalweight = totalweight + weights.total;
                            totalekoweight = totalekoweight + weights.eko;
                        }
                    }
                });
            }

            let newEkoPros = 0;
            if (ekoProsUsePrice) {
                newEkoPros = (totalekoprice * 100) / totalprice;
            } else {
                newEkoPros = (totalekoweight * 100) / totalweight;
                //console.log('List: ' + newEkoPros+'% = ('+totalekoweight+' * 100) / '+totalweight);
                // 59.20979913383374% = (11785 * 100) / 19903.8
            }
            if (!newEkoPros) {
                newEkoPros = 0;
            }
            // if 100 then downgrade to 99
            if (newEkoPros > 99 && newEkoPros < 100) {
                setEkoPros(99);
            } else {
                setEkoPros(newEkoPros);
            }
            
        }
    }

    const calcPortionsPrice = () => {
        if (selectedDate) {

            let portionPrice = 0.00;
            if (weekData && weekData.recipes && weekData.recipes.length>0) {
                weekData.recipes.forEach((weekrecipewitem) => {
                    if (selectedDate.date.format('DD.MM.yyyy') == weekrecipewitem.timestamp) {
                        let pp = checkNum(getRecipePortionPrice(weekrecipewitem.recipe));
                        if (pp) {
                            portionPrice = portionPrice + pp;
                        }

                        
                    }
                });
            }
            setPortionPrice(portionPrice);
        }
    }

    const calcAll = () => {
        if (selectedDate) {
            calcPortionsPrice();
            calcEkoPros();
            calcNutritionData();
		}
    }

    useEffect(() => {
		if (selectedDate) {
            loadWeek(selectedDate.week, selectedDate.year)
		}
	}, [selectedDate.week])

    useEffect(() => {
		calcAll();
	}, [selectedDate.date, ekoProsUsePrice, energyUsePortion, weekData])

    let timer;
    useEffect(() => {
        if (filterStr.length>-1) {
            timer = setTimeout(() => {
                loadRecipes();
            }, 200);
        }
        
	}, [filterStr])

    useEffect(() => {
        loadRecipes();
	}, [filterOwn, filterModel, filterMaincourse, filterVege, filterSalad, filterAddon, filterMiddle, filterDessert])

    useEffect(() => {
		if (!weeksWithRecipes) {
            loadWeeksWithRecipes();
		}
	}, [])

	return (
        <>

            <div className={copyWeekModalVisible ? "modal fade show d-block" : "modal fade"} id="editModal" tabIndex="-1" aria-labelledby="editMaterialModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{t('lists.copyweek-title')}</h1>
                            <button type="button" className="btn-close" aria-label="Close" onClick={() => setCopyWeekModalVisible(false)}></button>
                        </div>
                        <div className="modal-body">
                            {selectedDate &&
                            <>
                                <div className="mb-3">
                                    <label htmlFor="recipe-type" className="form-label">{t('lists.copyweek-title-from')}</label>
                                    <select id="copyWeekFrom" className="form-select" value={copyWeekFrom} onChange={handleWeekFromChange}>
                                        {(weeksWithRecipes) &&
                                            <>
                                            {weeksWithRecipes.map((weekitem, w) => (
                                                <option key={weekitem.VKO+"|"+weekitem.YEAR} value={weekitem.VKO+"|"+weekitem.YEAR}>viikko {weekitem.VKO} / {weekitem.YEAR}</option>
                                            ))}
                                            </>
                                        }
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="recipe-type" className="form-label">{t('lists.copyweek-title-to')}</label>
                                    <select id="copyWeekTo" className="form-select" value={copyWeekTo} onChange={handleWeekToChange}>
                                        <WeeksNearBy />
                                    </select>
                                </div>

                                


                            </>
                            }
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-link" onClick={() => setCopyWeekModalVisible(false)}>{t('cancel')}</button>
                            <button type="button" className="btn btn-secondary" onClick={copyWeek}>{t('lists.copyweek-submit')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={addRecipeDialogVisible ? "modal fade show d-block" : "modal fade"} id="addRecipeDialog" tabIndex="-1" aria-labelledby="addRecipeDialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header flex-column pb-0">

                            <div className="d-flex w-100">
                                <div className="flex-fill">
                                    <div className="input-group mb-2 pe-2">
                                        <input ref={input => input && input.focus()} type="text" className="form-control" placeholder={t('lists.search-placeholder')} aria-label={t('lists.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                                        <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <div className="row">
                                    <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterOwn} id="filterOwn" name="filterOwn" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterOwn">{t('recipes.my-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterMaincourse} id="filterMaincourse" name="filterMaincourse" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterMaincourse">{t('recipes.main-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterVege} id="filterVege" name="filterVege" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterVege">{t('recipes.vege-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterAddon} id="filterAddon" name="filterAddon" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterAddon">{t('recipes.addon-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterSalad} id="filterSalad" name="filterSalad" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterSalad">{t('recipes.salad-recipes')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 small">
                                        <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterMiddle} id="filterMiddle" name="filterMiddle" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterMiddle">{t('recipes.middle-recipes')}</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={filterDessert} id="filterDessert" name="filterDessert" onClick={handleFilterCheckChange} />
                                                <label className="form-check-label" htmlFor="filterDessert">{t('recipes.dessert-recipes')}</label>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="d">
                                    <button type="button" className="btn-close mb-4" aria-label="Close" onClick={() => setAddRecipeDialogVisible(false)}></button>
                                </div>
                            </div>
                            <div className="w-100">
                                <div className="row table-header small no-border px-2">
                                    <div className="col-5">{t('recipes.table-name')}</div>
                                    <div className="col-3">{t('recipes.table-type')}</div>
                                    <div className="col-2 justify-content-center">{t('recipes.table-price2')}</div>
                                    <div className="col-2 justify-content-center">{t('recipes.table-eko2')}</div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body pt-0 px-4" style={{minHeight: "512px", maxHeight: "512px"}}>
                            {filteredRecipes &&
                            <>
                                {filteredRecipes.map((recipe) =>
                                <div key={recipe.id} className="row table-row small cursor-pointer" onClick={() => (changedRecipeId!=null ? changeRecipe(recipe) : addRecipe(recipe))}>
                                    <div className="col-5">{recipe.NAME}</div>
                                    <div className="col-3 br-d">{recipe.TYPE}</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipePortionPrice(recipe)}&euro;</div>
                                    <div className="col-2 br-d justify-content-center">{getRecipeEkoPros(recipe)}%</div>
                                </div>
                            )}
                            </>
                            }
                        </div>
                    </div>
                </div>
            </div>


            {(addRecipeDialogVisible || copyWeekModalVisible) &&
                <div className="modal-backdrop fade show"></div>
            } 


            <div className="content-box green">
                <div className="row">
                    <div className="col-md-6">
                        <h3>{t('lists.search-title')}</h3>

                        <div className="row">
                            <div className="col-md-6">
                                
                                <div className="mb-3">
                                    <DatePicker 
                                        className="form-control" 
                                        locale="fi"
                                        dateFormat="d.M.yyyy"
                                        showWeekNumbers 
                                        highlightDates={calendarHighlightDates}
                                        selected={selectedDate.date.toDate()} 
                                        onChange={(date) => handleDateChange(date)} />
                                </div>
                            </div>
                            <div className="col-md-6">
                            
                                <div className="mb-3">
                                    <select id="TYPE" className="form-select" value={selectedDate.weekstr} onChange={handleWeekChange}>
                                        {(weeksWithRecipes) &&
                                            <>
                                            {weeksWithRecipes.map((weekitem, w) => (
                                                <option key={weekitem.VKO+"|"+weekitem.YEAR} value={weekitem.VKO+"|"+weekitem.YEAR}>viikko {weekitem.VKO} / {weekitem.YEAR}</option>
                                            ))}
                                            </>
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <h3>&nbsp;</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-secondary" onClick={addRecipeRow}>{t('lists.addrecipe')}</button>
                            </div>
                            <div className="col-md-6 align-items-end">
                                <a className="btn btn-default me-3" href={erkconfig.BASEURL + "/apps/ruokalistaviikko-tulostus-pdf.groovy?week=" + selectedDate.date.format('W') + "&year=" + selectedDate.date.format('yyyy') + "&token=" + token} target="_blank">{t('lists.printweek')}</a>
                                <button className="btn btn-default" onClick={showCopyWeek}>{t('lists.copyweek')}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Navigation />

            <div className="row sticky-container">
                <div className="col-md-8">

                    <div className="content-box">

                        <WeekDay daynro={0} />
                        <WeekDay daynro={1} />
                        <WeekDay daynro={2} />
                        <WeekDay daynro={3} />
                        <WeekDay daynro={4} />
                        <WeekDay daynro={5} />
                        <WeekDay daynro={6} />

                    </div>

                </div>
                <div className="col-md-4 sticky-element">

                    <div className="content-box">

                        <h4 className="detailmargin">PÄIVÄKOHTAISET TIEDOT<br/>{selectedDate.date.format('dddd')} - {selectedDate.date.format('D.M.yyyy')}</h4>

                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <h4 className="detailmargin">Annoksen <br/> hinta</h4>
                            </div>
                            <div className="col-md-6">
                            <h4 className="detailmargin">Luomuprosentti<br/><span id="ekoweight" className={ekoProsUsePrice ? "unactive_type" : "active_type"} onClick={(() => setEkoProsUsePrice(false))}>Kilo</span> / <span id="ekoprice" className={ekoProsUsePrice ? "active_type" : "unactive_type"} onClick={(() => setEkoProsUsePrice(true))}>Hinta</span> </h4>
                            </div>
                        </div>		

                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <div className="bluebox box"><h4 id="portionprice">{getNumber(portionPrice)}&euro;</h4></div>
                            </div>
                            <div className="col-md-6">
                                <div className="greenbox box"><h4 id="ekopros">{getNumber(ekoPros, 0)}%</h4></div>
                            </div>
                        </div>		


                        <div className="row" style={{borderBottom: "1px solid #ddd"}}>
                            <div className="col-xs-12">
                            <h4 className="detailmargin">Energia<br/><span id="perAnnos" className={energyUsePortion ? "active_type" : "unactive_type"} onClick={(() => setEnergyUsePortion(true))}>per annos</span> / <span id="perSata" className={energyUsePortion ? "unactive_type" : "active_type"} onClick={(() => setEnergyUsePortion(false))}>per 100g</span> </h4>
                            </div>
                        </div>	

                        <div className="row">
                            <div className="col-md-6" style={{borderRight: "1px solid #ddd"}}>
                                <div className="greybox box">
                                    <h4 id="portionkcal">{getNumber(nutritionData.kcal, 0)} <span className="small">kcal</span></h4>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div id="graph" className="graphcontainer" style={{width: "145px", height: "140px"}}>
                                    <BaseCircleChart stats={{
                                        rasva: nutritionData.rasvapros, 
                                        hiilihydraatit: nutritionData.hiilihydraatitpros, 
                                        proteiinit: nutritionData.proteiinipros
                                    }} />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <h4 className="detailmargin">Ravintosisältö</h4>
                                <table className="table nutritiontable">
                                    <tbody>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#989484"}}></div></td>
                                            <td>rasva</td>
                                            <td className="text-end"><span id="rasva_g">{getNumber(nutritionData.rasva)}</span>g</td>
                                            <td className="text-end"><span id="rasva_p">{getNumber(nutritionData.rasvapros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><small>- josta tyydyttynyttä</small></td>
                                            <td className="text-end"><span id="rasvatyyd_g">{getNumber(nutritionData.rasvatyyd)}</span>g</td>
                                            <td className="text-end"><span id="rasvatyyd_p">{getNumber(nutritionData.rasvatyydpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#B17F1F"}}></div></td>
                                            <td>hiilihydraatit</td>
                                            <td className="text-end"><span id="hiilihydraatit_g">{getNumber(nutritionData.hiilihydraatit)}</span>g</td>
                                            <td className="text-end"><span id="hiilihydraatit_p">{getNumber(nutritionData.hiilihydraatitpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td><small>- josta sokereita</small></td>
                                            <td className="text-end"><span id="hiilihydraatitsok_g">{getNumber(nutritionData.hiilihydraatitsok)}</span>g</td>
                                            <td className="text-end"><span id="hiilihydraatitsok_p">{getNumber(nutritionData.hiilihydraatitsokpros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td><div className="graphcolor" style={{backgroundColor: "#E8901F"}}></div></td>
                                            <td>proteiini</td>
                                            <td className="text-end"><span id="proteiini_g">{getNumber(nutritionData.proteiini)}</span>g</td>
                                            <td className="text-end"><span id="proteiini_p">{getNumber(nutritionData.proteiinipros)}</span>%</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>ravintokuitu</td>
                                            <td className="text-end"><span id="ravintokuitu_g">{getNumber(nutritionData.ravintokuitu)}</span>g</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>suola</td>
                                            <td className="text-end"><span id="suola_g">{getNumber(nutritionData.suola)}</span>g</td>
                                            <td className="text-end"></td>
                                        </tr>						
                                        <tr>
                                            <td></td>
                                            <td>A-vit</td>
                                            <td className="text-end"><span id="avit_g">{getNumber(nutritionData.avit)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>D-vit</td>
                                            <td className="text-end"><span id="dvit_g">{getNumber(nutritionData.dvit)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>C-vit</td>
                                            <td className="text-end"><span id="cvit_g">{getNumber(nutritionData.cvit)}</span>mg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>B1 tiamiini</td>
                                            <td className="text-end"><span id="b1_g">{getNumber(nutritionData.b1)}</span>mg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>folaatti</td>
                                            <td className="text-end"><span id="folaatti_g">{getNumber(nutritionData.folaatti)}</span>µg</td>
                                            <td className="text-end"></td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>		

                    </div>
                    
                </div>
            </div>

            
        </>
    )
}
export default List;