import React, {useEffect, useState, useRef} from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2'
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from "react-i18next";


const BaseCircleChart = (props) => {

	const { t, i18n } = useTranslation();

    let options = {
        plugins: {
            legend: {
                display: false
            },
			title: {
				display: false,
				position: 'bottom',
				font: {
					size: 16,
					style: 'normal',
					weight: 'normal',
					lineHeight: '1.2'
				},
				color: '#333',
				text: ['']
			},
			tooltip: {
				enabled: true
			},
			responsive: true,
            datalabels: {
                display: true,
                color: '#ffffff',
                backgroundColor: 'transparent',
                font: {
                    size: 10,
                },
                formatter: function(value, context) {
                    return value + '%';
                }
            }
        },
        elements: {
            center: {
				text: '',
				color: '#333', 
				fontStyle: 'Arial',
				sidePadding: 40
			}
        },
        animation: {
            animateRotate : true,
            animateScale : true
        }
    }

	const plugins = [
        ChartDataLabels,
        {
		beforeDraw: function(chart) {
			if (chart.config.options.elements.center) {
				//Get ctx from string
				var ctx = chart.ctx;
		  
				//Get options from the center object in options
				var centerConfig = chart.config.options.elements.center;
				var fontStyle = centerConfig.fontStyle || 'Arial';
				var txt = centerConfig.text;
				var color = centerConfig.color || '#333';
				var sidePadding = centerConfig.sidePadding || 20;
				var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
				//Start with a base font of 30px
				ctx.font = "20px " + fontStyle;
		  
				//Get the width of the string and also the width of the element minus 10 to give it 5px side padding
				var stringWidth = ctx.measureText(txt).width;
				var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;
		  
				// Find out how much the font can grow in width.
				var widthRatio = elementWidth / stringWidth;
				var newFontSize = Math.floor(20 * widthRatio);
				var elementHeight = (chart.innerRadius * 2);
		  
				// Pick a new font size so it will not be larger than the height of label.
				var fontSizeToUse = Math.min(newFontSize, elementHeight);
		  
				//Set font settings to draw it correctly.
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
				var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
				ctx.font = fontSizeToUse+"px " + fontStyle;
				ctx.fillStyle = color;
		  
				//Draw text in center
				ctx.fillText(txt, centerX, centerY);
			}
		} 
	}]

    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [],
    });

	const randomValue = (min, max) => {
		let range = max - min;
		return min + range * Math.random();
	};

    useEffect(() => {

        const chart = chartRef.current;
        if (chart && props.stats) {
			let rasva = 0;
			let hiilihydraatit = 0;
			let proteiinit = 0;

			if (props.stats) {
				if (props.stats.rasva) {
					rasva = props.stats.rasva.toFixed(0)
				}
                if (props.stats.hiilihydraatit) {
					hiilihydraatit = props.stats.hiilihydraatit.toFixed(0)
				}
                if (props.stats.proteiinit) {
					proteiinit = props.stats.proteiinit.toFixed(0)
				}
			}

			//console.log(options)

			// for testing data 
			// rasva = randomValue(10, 33).toFixed(0)
            // hiilihydraatit = randomValue(10, 33).toFixed(0)
            // proteiinit = randomValue(10, 33).toFixed(0)

            setChartData({
                labels: [" rasva", " hiilihydraatit", " proteiinit"],
                datasets: [{
                    //labels: [rasva+"%", hiilihydraatit+"%", proteiinit+"%"],
                    data: [rasva, hiilihydraatit, proteiinit],
                    backgroundColor: ['#989484', '#B17F1F', '#E8901F'],
                    hoverBackgroundColor: ['#c7c2b0', '#e0a431', '#e8b36e'],
                    borderColor: ['#989484', '#B17F1F', '#E8901F'],
                    hoverBorderColor: ['#c7c2b0', '#e0a431', '#e8b36e']
                }]
            });
        }
    }, [props.stats]);

	let width = props.width;
	if (!width) {
		width = '110px'
	}

	let height = props.height;
	if (!height) {
		height = '110px'
	}

	return (
		<>
		<div className="mx-auto mt-3" style={{width: width, height: height}}>
			{props.stats &&
			<>
				<Doughnut data={chartData} options={options} plugins={plugins} ref={chartRef} />
			</>
			}
		</div>
		</>
    );
}

export default BaseCircleChart;