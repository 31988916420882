import React, { Suspense, useEffect } from 'react';
import {
	createBrowserRouter,
	RouterProvider,
    useNavigate,
	Navigate,
    NavLink
} from "react-router-dom";
import axios from 'axios';
import EkoruokakoneApp from './EkoruokakoneApp';
import Navigation from './Navigation';
import Start from './Start';
import Recipe from './Recipe';
import List from './List';
import Recipes from './Recipes';
import ReplaceRecipes from './ReplaceRecipes'
import Materials from './Materials';
import CompareWeek from './Compare/CompareWeek'
import ErrorPage from './ErrorPage';
import {getParameterByName} from './Utils/RecipeTools'
import erkconfig from "./Configuration"

const PrivateRoute = ({ children }) => {
	const auth = useAuth();
	const token = localStorage.getItem('t');
	//console.log(token)
	if (token || (auth && auth.user)) {
		return children
	}
	return <Navigate to="/" />
}

const router = createBrowserRouter([
	{
		path: "/",
		element: <EkoruokakoneApp />,
		errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Start />,
            },
            {
                path: "/recipe",
                element: <Recipe />,
            },
            {
                path: "/recipe/:recipeid",
                element: <Recipe />,
            },
            {
                path: "/list",
                element: <List />,
            },
            {
                path: "/recipes",
                element: <Recipes />,
            },
            {
                path: "/replacerecipes",
                element: <ReplaceRecipes />,
            },
			{
                path: "/materials",
                element: <Materials />,
            },
            {
                path: "/compareweek",
                element: <CompareWeek />,
            }
        ]
	},
    ],{
        basename: "/",
    });

export default function Ekoruokakone() {
    let token = getParameterByName("tkn")
    if (token) {
        localStorage.setItem('t', token);
    }

    return (
        <Suspense fallback="loading">
            <RouterProvider router={router} />
        </Suspense>
    );
}

