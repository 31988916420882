import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Navigation from './Navigation';
import {getCurrency, MaterialName, loadUsermaterials, getMaterialPrice} from './Utils/RecipeTools'
import PaginationList from './Utils/PaginationList';
import erkconfig from "./Configuration"

const Materials = ({ children }) => {

    const { t } = useTranslation();

    const token = localStorage.getItem('t');
    const decimalFormatter = new Intl.NumberFormat('fi', { minimumFractionDigits: 2 });
    const currencyFormatter = new Intl.NumberFormat('fi', { style: "currency", currency: "EUR" });
    
    const [isDemoUser, setIsDemoUser] = React.useState(false);

    const [selectedMaterial, setSelectedMaterial] = React.useState(null);
    const [materials, setMaterials] = React.useState(null);
    const [filterStr, setFilterStr] = React.useState("");
    const [filterOwn, setFilterOwn] = React.useState(false);
    const [filterLuomu, setFilterLuomu] = React.useState(false);
    const [sortby, setSortBy] = React.useState("NAME");
    const [sort, setSort] = React.useState("asc");

    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [producerModalVisible, setProducerModalVisible] = React.useState(false);

    const [producers, setProducers] = React.useState(null);

    const handleFilterChange = (event) => {
        let value = event.target.value;
        setFilterStr(value);
    }

    const handleFilterCheckChange = (event) => {
        let field = event.target.id;
        if (field=='filterOwn') {
            setFilterOwn(!filterOwn);
        } else if (field=='filterLuomu') {
            setFilterLuomu(!filterLuomu);
        }
    }

    const handleUsermaterialChange = (event) => {
        let field = (event.target.type === 'radio') ? event.target.name : event.target.id;
        let value = (event.target.type === 'checkbox') ? event.target.checked : event.target.value;
        setSelectedMaterial({
			...selectedMaterial,
			[field]: value
		});
    }

    const saveUserMaterial = () => {
        let params = {
            token: token,
            usermaterial: selectedMaterial
        };
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=save', params)
        .then(function (response) {
            if (response.data.success) {
                closeEditModal()
                setSelectedMaterial(null)
                loadMaterials();
                loadUsermaterials();
            }
        })
        .catch(function(error) {
        });
    }

    const loadMaterials = () => {
        setMaterials(null)
        let params = {
            token: token
        };
        if (sortby) {
            params.sortby = sortby
        }
        if (sort) {
            params.sort = sort
        }
        if (filterStr) {
            params.filter = filterStr
        }
        if (filterOwn) {
            params.filterown = true
        }
        if (filterLuomu) {
            params.filterluomu = true
        }
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=search', params)
        .then(function (response) {
            if (response.data.success) {
                setMaterials(response.data.materials)
                setIsDemoUser(response.data.isdemouser)
                setSelectedMaterial(null)
            }
        })
        .catch(function(error) {
        });
    }

    const loadProducers = (item) => {
        setProducers(null)
        let params = {
            token: token
        };
        if (item.id) {
            params.materialid = item.id
        }
        axios.post(erkconfig.BASEURL + '/apps/erk_materials?action=producers', params)
        .then(function (response) {
            if (response.data.success) {
                setProducers(response.data.producers)
            }
        })
        .catch(function(error) {
        });
    }

    const displayMaterialRow = (item, props) => {
        return (
            <div key={item.id} className="row table-row">
                <div className="col-3 br-d">
                    <MaterialName {...item} />
                </div>
                <div className="col-2 br-d justify-content-center">
                    {!isDemoUser &&
                        <>
                            {item.PRODUCER && <a href="#" onClick={(event) => openProducerModal(event, item)}><i className="bi bi-cart-fill checkmark"></i></a>}
                        </>
                    }
                </div>
                <div className="col-2 br-d justify-content-end">{currencyFormatter.format(getMaterialPrice(item))}</div>
                <div className="col-2 br-d justify-content-center">
                    {item.EKO ? <i className="bi bi-check-circle-fill ekomark eko"></i> : <i className="bi bi-check-circle-fill ekomark"></i>}
                </div>
                <div className="col-2 br-d justify-content-center">{item.usermaterialid && <i className="bi bi-check-circle-fill checkmark"></i>}</div>
                <div className="col-1 justify-content-center"><a href="#" onClick={(event) => openEditModal(event, item)}><i className="bi bi-gear-fill checkmark"></i></a></div>
            </div>
        );
    }

    const getOriginalPrice = (item) => {
        let returnprice = 0.00;
        if (item) {
            if (item.ORIGINALPRICE) {
                returnprice = item.ORIGINALPRICE;
            } else if (item.PRICE) {
                returnprice = item.PRICE;
            }
        }
        return currencyFormatter.format(returnprice);
    }

    const openEditModal = (event, item) => {
        event.preventDefault();
        console.log(item)
        setSelectedMaterial(item)
        setEditModalVisible(true)
    }

    const closeEditModal = () => {
        setEditModalVisible(false)
    }

    const openProducerModal = (event, item) => {
        event.preventDefault();
        loadProducers(item)
        setProducerModalVisible(true)
    }

    const closeProducerModal = () => {
        setProducerModalVisible(false)
    }

    const getlink = (url) => {
        let newurl = url;
        if (newurl.indexOf('http')>-1) {

        } else {
            newurl = 'https://' + newurl
        }
        return newurl;
    }


    useEffect(() => {
        loadMaterials();
	}, [filterOwn, filterLuomu])

    useEffect(() => {
        if (filterStr=='' || filterStr.length>2) {
            loadMaterials();
        }
	}, [filterStr])

    useEffect(() => {
		if (!materials) {
            loadMaterials();
		}
	}, [])

	return (
        <>

            <div className={editModalVisible ? "modal fade show d-block" : "modal fade"} id="editModal" tabIndex="-1" aria-labelledby="editMaterialModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{t('materials.edit-title')}</h1>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeEditModal}></button>
                        </div>
                        <div className="modal-body">
                            {selectedMaterial &&
                            <>
                                <p><strong>{selectedMaterial.NAME}</strong></p>

                                <div className="mb-3">
                                    <label htmlFor="material-price" className="form-label">{t('materials.edit-price')}</label>
                                    <input type="text" className="form-control" id="PRICE" value={selectedMaterial.PRICE} onChange={handleUsermaterialChange}/>
                                    <div id="material-price-help" className="form-text">{t('materials.edit-originalprice')} {getOriginalPrice(selectedMaterial)}</div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="material-notes" className="form-label">{t('materials.edit-notes')}</label>
                                    <textarea className="form-control" id="PLAINTEXT" rows="4" value={selectedMaterial.PLAINTEXT} onChange={handleUsermaterialChange}></textarea>
                                </div>

                            </>
                            }
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-link" onClick={closeEditModal}>{t('cancel')}</button>
                            <button type="button" className="btn btn-secondary" onClick={saveUserMaterial}>{t('save')}</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className={producerModalVisible ? "modal fade show d-block" : "modal fade"} id="producerModal" tabIndex="-1" aria-labelledby="producerModal" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="staticBackdropLabel">{t('materials.producertitle')}</h1>
                            <button type="button" className="btn-close" aria-label="Close" onClick={closeProducerModal}></button>
                        </div>
                        <div className="modal-body">
                        {producers ? (
                            <>
                            {producers.map((producer) =>
                                <div key={producer.id}>
                                    <p>
                                    {producer.NAME &&
                                        <><strong>{producer.NAME}</strong><br/></>
                                    }
                                    {producer.ADDRESS &&
                                        <>{producer.ADDRESS}<br/></>
                                    }
                                    {(producer.ZIP && producer.CITY) &&
                                        <>{producer.ZIP} {producer.CITY}<br/></>
                                    }
                                    {producer.PHONE &&
                                        <>{producer.PHONE}<br/></>
                                    }
                                    {producer.EMAIL &&
                                        <>{producer.EMAIL}<br/></>
                                    }
                                    {producer.WWW &&
                                        <><a href={getlink(producer.WWW)} target="_blank">{getlink(producer.WWW)}</a><br/></>
                                    }
                                    </p>
                                </div>
                            )}
                            </>
                        ) : (
                            <div className="mx-auto my-3"><div className="spinner-grow p-3"></div></div>
                        )
                        }
                        </div>
                    </div>
                </div>
            </div>

            {(editModalVisible || producerModalVisible) &&
                <div className="modal-backdrop fade show"></div>
            } 


            <div className="content-box green">
                <div className="row align-items-end">
                    <div className="col-md-8">
                        <h3>{t('materials.search-title')}</h3>
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" placeholder={t('materials.search-placeholder')} aria-label={t('materials.search-placeholder')} value={filterStr} onChange={handleFilterChange}/>
                            <span className="input-group-text" id="basic-addon2"><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                    <div className="col-md-4">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={filterOwn} id="filterOwn" name="filterOwn" onClick={handleFilterCheckChange} />
                                <label className="form-check-label" htmlFor="filterOwn">{t('materials.my-material')}</label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value={filterLuomu} id="filterLuomu" name="filterLuomu" onClick={handleFilterCheckChange} />
                                <label className="form-check-label" htmlFor="filterLuomu">{t('materials.eko-material')}</label>
                            </div>
                    </div>
                </div>
            </div>

            <Navigation />

            <div className="content-box">
                <div className="row table-header">
                    <div className="col-3 titlehead br-d">{t("materials.table-name")}</div>
                    <div className="col-2 br-d justify-content-center">{t("materials.table-providers")}</div>
                    <div className="col-2 br-d justify-content-end">{t("materials.table-price")}</div>
                    <div className="col-2 br-d justify-content-center">{t("materials.table-eko")}</div>
                    <div className="col-2 br-d justify-content-center">{t("materials.table-own")}</div>
                    <div className="col-1 justify-content-center">{t("materials.table-edit")}</div>
                </div>

                {materials ? (
                    <PaginationList data={materials} limit={30} itemrender={displayMaterialRow} itemaction={displayMaterialRow}/>
                ) : (
                    <div className="mx-auto my-3"><div className="spinner-grow p-3"></div></div>
                )
                }

            </div>
        </>
    )
}
export default Materials;