import React from 'react';
import { useTranslation } from 'react-i18next';

import Navigation from './Navigation';
import erkconfig from "./Configuration"

const NoUser = ({ children }) => {
	return (
        <>  
            <div>
                <img className="header-icons" alt="Ruokaikonit" src={erkconfig.BASEURL + "/resources/images/header_ikonit.png"} />
            </div>

            <div className="content-box">
                <h1>Tervetuloa Ekoruokakoneeseen</h1>
                <p>Käyttäjää ei löytynyt! Ole hyvä ja <a href={erkconfig.BASEURL + "/"}>kirjaudu ohjelmaan.</a></p>

            </div>
        </>
    )
}
export default NoUser;